import { Icon } from '@iconify/react'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { pageTitle } from '../../helper'
import Cta from '../Cta'
import PageHeading from '../PageHeading'
import Div from '../Div'
import Spacing from '../Spacing'
import axios from "axios";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
export default function BlogDetailsPage() {
  const [data, setData] = useState([]);
  const [services, setServices] = useState([]);
  const routeParams = useParams();
  pageTitle('Blog Details');

  const { blogId } = routeParams;
  const fetchData = async () => {
    // Send an HTTP GET request to the specified URL
  const response = await axios.get(`https://www.api.mavenevents.ae/api/blog/${blogId}`);
    // Update the 'data' state with the data from the API response
    setData(response.data);
  };
  const fetchData2 = async () => {
    // Send an HTTP GET request to the specified URL
    const response = await axios.get("https://www.api.mavenevents.ae/api/services/");

    // Update the 'data' state with the data from the API response
    setServices(response.data);
  };
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const validationSchema = Yup.object().shape(
    {
      name: Yup.string()
        .required("Please enter name")
        .min(2, "Name must be at least 1 characters"),
      service: Yup.string().required("Please select a service"),
      businessName: Yup.string()
        .required("Please enter the Business name")
        .min(2, "The Business name must be at least 2 characters"),
      message: Yup.string().when("message", (val, schema) => {
        if (val?.length > 1) {
          //if address exist then apply min max else not
          return Yup.string()
            .min(5, "Message must be at least 5 characters")
            .required("Required");
        } else {
          return Yup.string().notRequired();
        }
      }),
      email: Yup.string()
        .required("Please enter the email ")
        .email("must be email format"),
      phone: Yup.string()
        .required("Please enter the phone number")
        .matches(phoneRegExp, "Phone number is not valid")
        .min(10, "The phone number must be 10 numbers")
        .max(10, "The phone number must be 10 numbers"),
    },
    [["message", "message"]]
  );
  const initialValues = {
    name: "",
    email: "",
    businessName: "",
    phone: "",
    message: "",
    service: "",
  };

  const onSubmit = async (values) => {
    const response = await axios.post("https://www.api.mavenevents.ae/api/appointments/",{
      ...values
    });
    if (Array.isArray(response.data.error)) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < response.data.error.length; i++) {
        toast.error(response.data.error[i].msg.message, {
          position: "bottom-right",
           draggable: false 
        })
      }
      return false
    } else{
      toast.success("Project Send 🎉", {
        position: "bottom-right",
        draggable: false 
      }) 
      return response.data
    }

  };
  useEffect(() => {
    window.scrollTo(0, 0)
    fetchData()
    fetchData2()
    // eslint-disable-next-line 
  }, [])
  return (
    <>
     {/* helmet */}
     <Helmet>
        <meta
          name="description"
          content={"Maven Events - "+ data.title }
        />
        <meta
          name="keywords"
          content="Maven Events, digital marketing, abu dhabi, storytelling, indoor photogoraphy, outdoor photography, market, Marketing strategy, SWOT analysis, Branding guideline, social media ads, advertisement, social media, social media management, brand, photography"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
    {/* Start Page Heading Section */}
      <PageHeading 
        title={data.title}
        bgSrc='/images/blog_details_hero_bg.webp'
        pageLinkText={"Blog " + blogId}
      />
      {/* End Page Heading Section */}

      {/* Start Blog Details */}
      <Spacing lg='150' md='80'/>
      <Div className="container">
        <Div className="row">
        <Div className="col-lg-2"></Div>
          <Div className="col-lg-9">

            {/* Start Details Post Content */}
            <Div className="cs-post cs-style2">
              <Div className="cs-post_thumb cs-radius_15">
                <img  src={`https://www.api.mavenevents.ae/blog/${data.imageFile}`} alt="Post" className="w-100 cs-radius_15" />
              </Div>
              <Div className="cs-post_info">
                <Div className="cs-post_meta cs-style1 cs-ternary_color cs-semi_bold cs-primary_font">
                  <span className="cs-posted_by">07 Mar 2022</span>
                </Div>
                <h2 className="cs-post_title">{data.title}</h2>
                <p>{`${data.description}`}</p>
                <br/>  <br/> 
                <text style={{ whiteSpace: 'pre-line' }}>{`${data.content}`}</text>
              </Div>
            </Div>
            {/* End Details Post Content */}

            {/* Start Comment Section */}
            <Spacing lg='30' md='30'/>
            <h2 className="cs-font_50 cs-m0" style={{color:'#f4b231'}}>Do you have a project in your mind?</h2>
            <Spacing lg='5' md='5'/>
            <p className="cs-m0">Your email address will not be published. Required fields are marked *</p>
            <Spacing lg='40' md='30'/>
            <Div className="">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              validator={() => ({})}
              onSubmit={async (values, { resetForm }) => {
                onSubmit(values).then((data)=>{
                  if (data) {
                    resetForm();
                  }
                });

              }}
            >
              <Form className="row">
                <Div className="col-sm-6">
                  <label className="cs-primary_color">Full Name*</label>
                  <Field
                    name="name"
                    type="text"
                    className="cs-form_field"
                    placeholder="your name..."
                  />
                  <ErrorMessage name="name">
                    {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                  </ErrorMessage>
                  <Spacing lg="20" md="20" />
                </Div>
                <Div className="col-sm-6">
                  <label className="cs-primary_color">Email*</label>
                  <Field
                    name="email"
                    type="text"
                    className="cs-form_field"
                    placeholder="your email..."
                  />
                  <ErrorMessage name="email">
                    {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                  </ErrorMessage>
                  <Spacing lg="20" md="20" />
                </Div>
                <Div className="col-sm-6">
                  <label className="cs-primary_color">Business Name*</label>
                  <Field
                    name="businessName"
                    type="text"
                    className="cs-form_field"
                    placeholder="your company..."
                  />
                  <ErrorMessage name="businessName">
                    {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                  </ErrorMessage>
                  <Spacing lg="20" md="20" />
                </Div>
                <Div className="col-sm-6">
                  <label className="cs-primary_color">Project Type*</label>
                  <Field name="service" as="select" className="cs-form_field">
                    <option value={""}>Select a Service</option>
                    {services.map((option) => (
                      <option key={option.name} value={option.name}>
                        {option.name}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage name="service">
                    {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                  </ErrorMessage>
                  <Spacing lg="20" md="20" />
                </Div>
                <Div className="col-sm-6">
                  <label className="cs-primary_color">Mobile*</label>
                  <Field
                    name="phone"
                    type="text"
                    className="cs-form_field"
                    placeholder="0x xxx xxxx"
                  />
                  <ErrorMessage name="phone">
                    {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                  </ErrorMessage>
                  <Spacing lg="20" md="20" />
                </Div>
                <Div className="col-sm-12">
                  <label className="cs-primary_color">Message</label>
                  <Field
                    name="message"
                    as="textarea"
                    cols="30"
                    rows="7"
                    type="text"
                    className="cs-form_field"
                    placeholder="write a message to know you more..."
                  />
                  <ErrorMessage name="message">
                    {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                  </ErrorMessage>
                  <Spacing lg="25" md="25" />
                </Div>
                <Div className="col-sm-12">
                  <button className="cs-btn cs-style1" type="submit">
                    <span>Submit</span>
                    <Icon icon="bi:arrow-right" />
                  </button>
                </Div>
              </Form>
            </Formik>
          </Div>
            {/* End Comment Section */}
          </Div>
        </Div>
      </Div>
      <Spacing lg='150' md='80'/>
      {/* Start Blog Details */}

      {/* Start CTA Section */}
      <Div className="container">
        <Cta 
          title='Let’s disscuse make <br />something <i>cool</i> together' 
          btnText='Contact Us' 
          btnLink='/contact' 
          bgSrc='/images/cta_bg.webp'
        />
      </Div>
      {/* End CTA Section */}
      <Spacing lg='80' md='40'/>
    </>
  )
}

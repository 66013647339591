import React, { useEffect } from "react";
import { pageTitle } from "../../helper";
import Cta from "../Cta";
import PageHeading from "../PageHeading";
import Div from "../Div";
import Spacing from "../Spacing";
import Client from "../Client";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
export default function ClientPage() {
  pageTitle("Our Clients");
  const clientLogo = [
    {
      memberImage: "/images/clientLogo/0.webp",
      memberName: "",
      memberDesignation: "",
      loading:'eager'
    },
    {
      memberImage: "/images/clientLogo/52.webp",
      memberName: "",
      memberDesignation: "",
      loading:'eager'
    },
  
       {
      memberImage: "/images/clientLogo/60.webp",
      memberName: "",
      memberDesignation: "",
      loading:'eager'
    }, 

    {
      memberImage: "/images/clientLogo/40.webp",
      memberName: "",
      memberDesignation: "",
      loading:'eager'
    },
    {
      memberImage: "/images/clientLogo/3.webp",
      memberName: "",
      memberDesignation: "",
      loading:'eager'
    },
    {
      memberImage: "/images/clientLogo/20.webp",
      memberName: "",
      memberDesignation: "",
      loading:'eager'
    },
    {
      memberImage: "/images/clientLogo/6.webp",
      memberName: "",
      memberDesignation: "",
      loading:'eager'
    },
    {
      memberImage: "/images/clientLogo/1.webp",
      memberName: "",
      memberDesignation: "",
      loading:'eager'
    },
    {
      memberImage: "/images/clientLogo/61.webp",
      memberName: "",
      memberDesignation: "",
      loading:'eager'
    }, 
      
      {
      memberImage: "/images/clientLogo/63.webp",
      memberName: "",
      memberDesignation: "",
      loading:'eager'
    },   
     {
      memberImage: "/images/clientLogo/64.webp",
      memberName: "",
      memberDesignation: "",
      loading:'eager'
    },   
     {
      memberImage: "/images/clientLogo/65.webp",
      memberName: "",
      memberDesignation: "",
      loading:'eager'
    },   
     {
      memberImage: "/images/clientLogo/66.webp",
      memberName: "",
      memberDesignation: "",
      loading:'eager'
    },
    {
      memberImage: "/images/clientLogo/00.webp",
      memberName: "",
      memberDesignation: "",
      loading:'eager'
    },



    {
      memberImage: "/images/clientLogo/19.webp",
      memberName: "",
      memberDesignation: "",
      loading:'eager'
    },
    {
      memberImage: "/images/clientLogo/11.webp",
      memberName: "",
      memberDesignation: "",
      loading:'eager'
    },

    
    {
      memberImage: "/images/clientLogo/10.webp",
      memberName: "",
      memberDesignation: "",
      loading:'eager'
    },
    

    {
      memberImage: "/images/clientLogo/2.webp",
      memberName: "",
      memberDesignation: "",
      loading:'eager'
    },
    {
      memberImage: "/images/clientLogo/50.webp",
      memberName: "",
      memberDesignation: "",
      loading:'lazy'
    },
    {
      memberImage: "/images/clientLogo/4.webp",
      memberName: "",
      memberDesignation: "",
      loading:'eager'
    },
    {
      memberImage: "/images/clientLogo/5.webp",
      memberName: "",
      memberDesignation: "",
      loading:'eager'
    },

    {
      memberImage: "/images/clientLogo/58.webp",
      memberName: "",
      memberDesignation: "",
      loading:'eager'
    },    
    {
      memberImage: "/images/clientLogo/59.webp",
      memberName: "",
      memberDesignation: "",
      loading:'eager'
    }, 
    {
      memberImage: "/images/clientLogo/7.webp",
      memberName: "",
      memberDesignation: "",
      loading:'eager'
    },
    {
      memberImage: "/images/clientLogo/8.webp",
      memberName: "",
      memberDesignation: "",
      loading:'eager'
    },
    {
      memberImage: "/images/clientLogo/9.webp",
      memberName: "",
      memberDesignation: "",
      loading:'eager'
    },


    {
      memberImage: "/images/clientLogo/12.webp",
      memberName: "",
      memberDesignation: "",
      loading:'eager'
    },
    {
      memberImage: "/images/clientLogo/13.webp",
      memberName: "",
      memberDesignation: "",
      loading:'eager'
    },
    {
      memberImage: "/images/clientLogo/14.webp",
      memberName: "",
      memberDesignation: "",
      loading:'eager'
    },
    {
      memberImage: "/images/clientLogo/15.webp",
      memberName: "",
      memberDesignation: "",
      loading:'eager'
    },
    {
      memberImage: "/images/clientLogo/16.webp",
      memberName: "",
      memberDesignation: "",
      loading:'eager'
    },
    {
      memberImage: "/images/clientLogo/17.webp",
      memberName: "",
      memberDesignation: "",
      loading:'eager'
    },
    {
      memberImage: "/images/clientLogo/18.webp",
      memberName: "",
      memberDesignation: "",
      loading:'eager'
    },


    {
      memberImage: "/images/clientLogo/21.webp",
      memberName: "",
      memberDesignation: "",
      loading:'eager'
    },
    {
      memberImage: "/images/clientLogo/22.webp",
      memberName: "",
      memberDesignation: "",
      loading:'eager'
    },
    {
      memberImage: "/images/clientLogo/23.webp",
      memberName: "",
      memberDesignation: "",
      loading:'lazy'
    },
    {
      memberImage: "/images/clientLogo/24.webp",
      memberName: "",
      memberDesignation: "",
      loading:'lazy'
    },
    {
      memberImage: "/images/clientLogo/25.webp",
      memberName: "",
      memberDesignation: "",
      loading:'lazy'
    },
    {
      memberImage: "/images/clientLogo/26.webp",
      memberName: "",
      memberDesignation: "",
      loading:'lazy'
    },
    {
      memberImage: "/images/clientLogo/27.webp",
      memberName: "",
      memberDesignation: "",
      loading:'lazy'
    },
    {
      memberImage: "/images/clientLogo/28.webp",
      memberName: "",
      memberDesignation: "",
      loading:'lazy'
    },
    {
      memberImage: "/images/clientLogo/29.webp",
      memberName: "",
      memberDesignation: "",
      loading:'lazy'
    },
    {
      memberImage: "/images/clientLogo/30.webp",
      memberName: "",
      memberDesignation: "",
      loading:'lazy'
    },
    {
      memberImage: "/images/clientLogo/31.webp",
      memberName: "",
      memberDesignation: "",
      loading:'lazy'
    },
    {
      memberImage: "/images/clientLogo/32.webp",
      memberName: "",
      memberDesignation: "",
      loading:'lazy'
    },
    {
      memberImage: "/images/clientLogo/33.webp",
      memberName: "",
      memberDesignation: "",
      loading:'lazy'
    },
    {
      memberImage: "/images/clientLogo/34.webp",
      memberName: "",
      memberDesignation: "",
      loading:'lazy'
    },
    {
      memberImage: "/images/clientLogo/35.webp",
      memberName: "",
      memberDesignation: "",
      loading:'lazy'
    },
    {
      memberImage: "/images/clientLogo/36.webp",
      memberName: "",
      memberDesignation: "",
      loading:'lazy'
    },
    {
      memberImage: "/images/clientLogo/37.webp",
      memberName: "",
      memberDesignation: "",
      loading:'lazy'
    },
    {
      memberImage: "/images/clientLogo/38.webp",
      memberName: "",
      memberDesignation: "",
      loading:'lazy'
    },
    {
      memberImage: "/images/clientLogo/67.webp",
      memberName: "",
      memberDesignation: "",
      loading:'lazy'
    },
    {
      memberImage: "/images/clientLogo/68.webp",
      memberName: "",
      memberDesignation: "",
      loading:'lazy'
    },
    {
      memberImage: "/images/clientLogo/39.webp",
      memberName: "",
      memberDesignation: "",
      loading:'lazy'
    },
    {
      memberImage: "/images/clientLogo/4.webp",
      memberName: "",
      memberDesignation: "",
      loading:'lazy'
    },

    {
      memberImage: "/images/clientLogo/41.webp",
      memberName: "",
      memberDesignation: "",
      loading:'lazy'
    },
    {
      memberImage: "/images/clientLogo/42.webp",
      memberName: "",
      memberDesignation: "",
      loading:'lazy'
    },
    {
      memberImage: "/images/clientLogo/43.webp",
      memberName: "",
      memberDesignation: "",
      loading:'lazy'
    },
    {
      memberImage: "/images/clientLogo/44.webp",
      memberName: "",
      memberDesignation: "",
      loading:'lazy'
    },
    {
      memberImage: "/images/clientLogo/45.webp",
      memberName: "",
      memberDesignation: "",
      loading:'lazy'
    },
    {
      memberImage: "/images/clientLogo/46.webp",
      memberName: "",
      memberDesignation: "",
      loading:'lazy'
    },
    {
      memberImage: "/images/clientLogo/47.webp",
      memberName: "",
      memberDesignation: "",
      loading:'lazy'
    },
    {
      memberImage: "/images/clientLogo/48.webp",
      memberName: "",
      memberDesignation: "",
      loading:'lazy'
    },
    {
      memberImage: "/images/clientLogo/49.webp",
      memberName: "",
      memberDesignation: "",
      loading:'lazy'
    },

    {
      memberImage: "/images/clientLogo/51.webp",
      memberName: "",
      memberDesignation: "",
      loading:'lazy'
    },

    {
      memberImage: "/images/clientLogo/53.webp",
      memberName: "",
      memberDesignation: "",
      loading:'lazy'
    },
    {
      memberImage: "/images/clientLogo/54.webp",
      memberName: "",
      memberDesignation: "",
      loading:'lazy'
    },
    {
      memberImage: "/images/clientLogo/62.webp",
      memberName: "",
      memberDesignation: "",
      loading:'eager'
    },  
    {
      memberImage: "/images/clientLogo/55.webp",
      memberName: "",
      memberDesignation: "",
      loading:'lazy'
    },
    {
      memberImage: "/images/clientLogo/56.webp",
      memberName: "",
      memberDesignation: "",
      loading:'lazy'
    },
    {
      memberImage: "/images/clientLogo/57.webp",
      memberName: "",
      memberDesignation: "",
      loading:'lazy'
    },
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
    // fetchData();
  }, []);

  return (
    <>
    <Helmet>
        <meta
          name="description"
          content="Maven Events - our devoted clients" 
        />
        <meta
          name="keywords"
          content="Maven Events, photography services, abu dhabi, devoted clients, storytelling, Branding guideline, social media, social media management, brand, photography, videography. digital marketing, advertisement, meta ads, social media ads"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <PageHeading
        title="Our Devoted Clients"
        bgSrc="images/about_hero_bg.webp"
        pageLinkText="Our Clients"
      />

      <div style={{ backgroundImage: 'url("/images/a_Background/1.webp")' }}>
        <Spacing lg="100" md="60" />
        <Div className="container">
          <Div className="row">
            {clientLogo.map((item, index) => (
              <Div key={index} className="col-lg-2 col-sm-4 col-6 ">
                <Client
                  memberImage={item.memberImage}
                  memberName={""}
                  memberDesignation={""}
                  memberSocial={item.memberSocial}
                  id={item.id}
                  loading={item.loading}
                />
                <Spacing lg="40" md="15" />
              </Div>
            ))}
            <Div
              key={"index"}
              className="col-lg-2 col-sm-4 col-6 "
            >
              <div style={{padding:"20px",paddingTop:'10px',transform: 'scale(1.06) translateY(8px)'}} className="cs-team cs-style1">
                <Link to="/contact" >
                <img src="/images/clientLogo/icon.webp" alt="client logo"/>
                </Link>

              </div>
              <Spacing lg="40" md="15" />
            </Div>
          </Div>
          <Spacing lg="80" md="30" />
          <Cta
            title="unlock your potential <br/>Join our team today!"
            btnText=""
            btnLink=""
            bgSrc="/images/cta_bg.webp"
          />
          <Spacing lg="90" md="45" />
        </Div>
      </div>
    </>
  );
}

import React, { useEffect, useState } from 'react'
import { pageTitle } from '../../helper'
import Cta from '../Cta'
import Div from '../Div'
import Spacing from '../Spacing'
import SocialWidget from '../Widget/SocialWidget'
import { Link, useParams } from 'react-router-dom';
import axios from "axios";
import { Icon } from "@iconify/react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";

export default function CareerDetails() {
  pageTitle('Career Detail');
  const routeParams = useParams();
  const [data, setData] = useState(false);
  const [open, setOpen] = useState(false)
  const [file, setfile] = useState(null)
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const onSubmit = async (values,file) => {
    const response = await axios.post("https://www.api.mavenevents.ae/api/userCareer/",{
      ...values,
      cvFile:file
    },  { headers: {
      'Content-Type': 'multipart/form-data',
    } });
    if (Array.isArray(response.data.error)) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < response.data.error.length; i++) {
        toast.error(response.data.error[i].msg.message, {
          position: "bottom-right",
           draggable: false 
        })
      }
      return false
    } else{
      toast.success("Request Send 🎉", {
        position: "bottom-right",
        draggable: false 
      }) 
      return response.data
    }

  };
  const validationSchema = Yup.object().shape(
    {
      name: Yup.string()
        .required("Please enter name")
        .min(2, "Name must be at least 1 characters"),
      message: Yup.string().when("message", (val, schema) => {
        if (val?.length > 1) {
          //if address exist then apply min max else not
          return Yup.string()
            .min(5, "Message must be at least 5 characters")
            .required("Required");
        } else {
          return Yup.string().notRequired();
        }
      }),
      email: Yup.string()
        .required("Please enter the email ")
        .email("must be email format"),
    },
    [["message", "message"]]
  );
  const initialValues = {
    name: "",
    email: "",
    career: "",
    cvFile: '',
    message: "",
  };




  const { id } = routeParams;
  const fetchData = async () => {
    // Send an HTTP GET request to the specified URL
    const response = await axios.get(`https://www.api.mavenevents.ae/api/careers/website/${id}`);
    // Update the 'data' state with the data from the API response
    setData(response.data);
  };
  useEffect(() => {
    fetchData()
    window.scrollTo(0, 0)
    // eslint-disable-next-line 
  }, [])
  return (
    <>
    {/* helmet */}
    <Helmet>
        <meta
          name="description"
          content="Join us in Maven Events - job Description"
        />
        <meta
          name="keywords"
          content="Job description, Maven Events, jobs, abu dhabi, our team, marketing agency, designer, business, photographer, videographer, graphic designer, content creator, digital marketing"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
    <Div
      className="cs-page_heading cs-style1 cs-center text-center cs-bg"
      style={{ backgroundImage: `url(/images/team_hero_bg.webp)` }}
    >
      <Div className="container">
        <Div className="cs-page_heading_in">
          <h1 className="cs-page_title cs-font_50 cs-white_color" style={{color:"#f4b231"}}>Career Details</h1>
          <ol className="breadcrumb text-uppercase">
            <li className="breadcrumb-item">
              <Link to="/career">Careers</Link>
            </li>
            <li className="breadcrumb-item active">Career Details</li>
          </ol>
        </Div>
      </Div>
    </Div>

      <Spacing lg='150' md='80'/>
      <Div className="container">
        <Div className="row align-items-center">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-radius_15 cs-shine_hover_1">
              <img src="/images/misfotos/MAVENjob.webp" alt="job" className="w-100" />
            </Div>
          </Div>
          <Div className="col-lg-6 offset-xl-1">
            <Spacing lg='0' md='45'/>
            <Div className="cs-section_heading cs-style1">
              <h2 className="cs-section_title" style={{color:"#f4b231"}}>{data.position?.toUpperCase()}</h2>
              <Div className="cs-height_10 cs-height_lg_10" />
              <h3 className="cs-section_subtitle">{data.description}</h3>
              <Div className="cs-height_5 cs-height_lg_5" />
              <Div className="cs-separator cs-accent_bg" />
              <Div className="cs-height_10 cs-height_lg_10" />
              <div>
                  <h3>Skills</h3>
                  <ul>
                     {data && data?.skills.map((item, index) => (
                      <li key={item.id}>{item.description}</li>
                      ))}
                  </ul>
              </div>
              <Div className="cs-height_5 cs-height_lg_10" />
              <div>
                  <h3>Responsibilities</h3>
                  <ul>
                  {data && data?.responsabilities.map((item, index) => (
                      <li  key={item.id} >{item.description}</li>
                      ))}
                  </ul>
              </div>
              <Div className="cs-height_10 cs-height_lg_10" />
              <h5 style={{color:'#999696',fontSize:'14px'}}>
                <div style={{textDecoration:'underline'}}>
              How to Apply:<br/><br/>
                </div>
                Please include: "{data.position} - [Your Full Name]" in the subject line.<br/>
              send your resume or/and portfolio detailing your relevant experience to team@mavenevents.ae <br/>
              </h5>
              <Div className="cs-height_5 cs-height_lg_5" />
              <Div className="col-sm-12">
                  <button className="cs-btn cs-style1" onClick={handleClickOpen} >
                    <span>Apply now</span>
                    <Icon icon="bi:arrow-right" />
                  </button>
                </Div>
                <Div className="cs-height_10 cs-height_lg_10" />
             <Div className="cs-height_10 cs-height_lg_5" />
              <SocialWidget/>
            </Div>
            

            <Div className="container" style={{backgroundColor:'#181818'}}>
            <Div className="row" style={{backgroundColor:'#181818'}}>

         

        
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={'md'}
      >
        <DialogTitle style={{backgroundColor:'#181818', color:'#f4b231'}}>{data && data.position} Job Application</DialogTitle>
        <DialogContent style={{backgroundColor:'#181818'}}>
          <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              validator={() => ({})}
              onSubmit={async (values, { resetForm , setSubmitting }) => {
                setSubmitting(true)
                values.career = data.position
                onSubmit(values,file).then((data)=>{
                  if (data) {
                    resetForm();
                    handleClose();
                    setfile(null);
                  }
                });

              }}
            >
                {({ setFieldValue }) => (
              <Form className="row" style={{backgroundColor:'#181818'}}>
                <Div className="col-sm-6">
                  <label className="cs-primary_color">Full Name*</label>
                  <Field
                    name="name"
                    type="text"
                    className="cs-form_field"
                    placeholder="your name..."
                  />
                  <ErrorMessage name="name">
                    {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                  </ErrorMessage>
                  <Spacing lg="20" md="20" />
                </Div>
                <Div className="col-sm-6">
                  <label className="cs-primary_color">Email*</label>
                  <Field
                    name="email"
                    type="text"
                    className="cs-form_field"
                    placeholder="your email..."
                  />
                  <ErrorMessage name="email">
                    {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                  </ErrorMessage>
                  <Spacing lg="20" md="20" />
                </Div>
                <Div className="col-sm-6">
                  <label className="cs-primary_color">Career*</label>
                  <Field
                    name="career"
                    type="text"
                    disabled
                    value={data.position}
                    className="cs-form_field"
                    placeholder="your career..."
                  />
                  <ErrorMessage name="career">
                    {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                  </ErrorMessage>
                  <Spacing lg="20" md="20" />
                </Div>
                <Div className="col-sm-6">
                  <label className="cs-primary_color">CV File*</label>
                  <input
                    name="cvFile"
                    style={{cursor:'pointer'}}
                    type="file"
                    accept = 'application/pdf'
                    className="cs-form_field"
                    placeholder="cvExample.pdf"
                    onChange={(e) => {
                      // Object is possibly null error w/o check
                      if (e.target.files) {
                        setfile( e.currentTarget.files[0]);
                      }
                    }}
                    
                  />
                  <ErrorMessage name="cvFile">
                    {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                  </ErrorMessage>
                  <Spacing lg="20" md="20" />
                </Div>
                <Div className="col-sm-12">
                  <label className="cs-primary_color">Message</label>
                  <Field
                    name="message"
                    as="textarea"
                    cols="30"
                    rows="5"
                    type="text"
                    className="cs-form_field"
                    placeholder="write a message to know you more..."
                  />
                  <ErrorMessage name="message">
                    {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                  </ErrorMessage>
                  <Spacing lg="25" md="25" />
                </Div>
                <DialogActions style={{backgroundColor:'#181818'}}>
                <Div className="">
                  <button className="cs-btn cs-style1" type='button' onClick={handleClose}>
                    <span>Cancel</span>
                  </button>
                </Div>
                <Div className="">
                  <button className="cs-btn cs-style1" type="submit">
                    <span>Submit</span>
                  </button>
                </Div>
        </DialogActions>
              </Form>
              )}
            </Formik>
        </DialogContent>
        
      </Dialog>
      </Div>
      </Div>

          </Div>
        </Div>
      </Div>
      <Spacing lg='150' md='80'/>
      <Div className="container">
        <Cta 
          title='Join our creative team and  <br /> let your talents shine!' 
          btnText='' 
          btnLink='' 
          bgSrc='/images/cta_bg.webp'
        />
      </Div>
      <Spacing lg='80' md='40'/>
    </>
  )
}

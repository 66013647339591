import React, { useEffect } from "react";
import Hero4 from "../Hero/Hero4";
import { pageTitle } from "../../helper";
import Div from "../Div";
import FunFact2 from "../FunFact/FunFact2";
import Spacing from "../Spacing";
import SectionHeading from "../SectionHeading";
import MovingText2 from "../MovingText/MovingText2";
import VideoModal from "../VideoModal";
import Cta from "../Cta";
import MovingText22 from "../MovingLogo";
import { Helmet } from "react-helmet";

export default function DigitalAgencyHome() {
  pageTitle("Digital Agency");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const heroSocialLinks = [
    {
      name: "Instagram",
      links: "https://www.instagram.com/maven.ae",
    },
    {
      name: "Facebook",
      links: "https://www.facebook.com/people/Maven-Events/61557239189183/",
    },
    {
      name: "YouTube",
      links: "https://www.youtube.com/channel/UCD-sZpls3rdmwedzap3yqEA",
    },
    {
      name: "TikTok",
      links: "https://www.tiktok.com/@maven.ae",
    },
  ];
  const funfaceData = [
    {
      title: "Years of History",
      factNumber: "16",
    },
    {
      title: "Projects Completed",
      factNumber: "650",
    },
    {
      title: "Happy Clients",
      factNumber: "230",
    },
    {
      title: "Services and more",
      factNumber: "15",
    },
  ];
  const serviceData1 = [
    {
      title: "Social Media Marketing",
      href: "#",
    },
    {
      title: "SEO",
      href: "#",
    },
    {
      title: "Website & QR Design",
      href: "#",
    },
    {
      title: "Graphic Design",
      href: "#",
    },
  ];
  const serviceData2 = [
    {
      title: "Voice Over",
      href: "#",
    },
    {
      title: "Education Programs",
      href: "#",
    },
    {
      title: "Professional DJ",
      href: "#",
    },
    {
      title: "Exhibitions",
      href: "#",
    },
    {
      title: "Email Marketing",
      href: "#",
    },
    {
      title: "Digital Marketing",
      href: "#",
    },
    {
      title: "Broadcast",
      href: "#",
    },
  ];
  // const portfolioData = [

  //   {
  //     title: 'Menu Photoshoot',
  //     src: '/images/a_website pictures/cafe & restaurants/22.webp',
  //   },

  //   {
  //     title: 'Cafe & Restaurants',
  //     src: '/images/a_website pictures/cafe & restaurants/12.webp',
  //   },
  //   {
  //     title: 'Cafe & Restaurants',
  //     src: '/images/a_website pictures/cafe & restaurants/13.webp',
  //   },
  //   {
  //     title: 'Menu Photoshoot',
  //     src: '/images/a_website pictures/menu photoshoot/2.webp',
  //   },

  //   {
  //     title: 'Lifestyle',
  //     src: '/images/a_website pictures/lifestyle/15-1.webp',
  //   },
  //   {
  //     title: 'Portraits',
  //     src: '/images/a_website pictures/portraits/2.webp',
  //   },
  //   {
  //     title: 'Portraits',
  //     src: '/images/a_website pictures/portraits/1.webp',
  //   },
  //   {
  //     title: 'Events',
  //     src: '/images/a_website pictures/events/5.webp',
  //   },

  //   {
  //     title: 'Business',
  //     src: '/images/a_website pictures/business/1.webp',
  //   },


  //   {
  //     title: 'SPA & Clinics',
  //     src: '/images/a_website pictures/spa & clinics/L4-1.webp',
  //   },
  //   {
  //     title: 'Wedding',
  //     src: '/images/a_website pictures/wedding/13-1.webp',
  //   },
  // ];
  return (
    <>
    {/* hemlet */}
    <Helmet>
        <meta
          name="description"
          content="Maven Events - Digital Agency" 
        />
        <meta
          name="keywords"
          content="Maven Events, abu dhabi, Digital Agency, partners, 
          Professional Photographers, Professional Videographers, High quality editing, Promotional videos, Social media content, Profile Optimization, Posting & Advertising, Technical SEO, On-Page SEO, Off-Page SEO, 
          E-commerce, Shopify, Custom Web, WordPress Development, Digital Designing, Printing Material, Voice Over, Creative Development Resources, Informational Email, Transactional Email & Digital Newsletter"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      {/* Start Hero Section */}
      <Hero4
        title="We Are <span>M</span>aven <br />Digital Agency"
        subtitle="We deliver best problem solving solution for our client and provide finest finishing product in present and upcoming future."
        scrollDownId="#service"
        socialLinksHeading="Follow Us"
        heroSocialLinks={heroSocialLinks}
        phoneNumber="+971 52 486 7771"
        email="info@mavenevents.ae"
      />
      {/* End Hero Section */}

      {/* Start Funfact Section */}
      <Div className="container">
        <FunFact2
          data={funfaceData}
          variant="cs-type1"
          bgUrl="/images/funfact_shape_bg.svg"
        />
      </Div>
      {/* End Funfact Section */}

      {/* Start Services Section */}
      <Spacing lg="145" md="80" />
      <Div className="container">
        <SectionHeading
          title="What we provide"
          subtitle="Services"
          variant="cs-style1 text-center"
        />
        <Spacing lg="65" md="45" />
      </Div>
      {/* End Services Section */}

      {/* Start Moving Text Section */}
      <MovingText2 data={serviceData1} />
      <Spacing lg="20" md="10" />
      <MovingText2 reverseDirection data={serviceData2} />
      {/* End Moving Text Section */}

      {/* Start About Section */}
      <Div className="cs-shape_wrap_4">
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>
        <Spacing lg="150" md="80" />
        <Div className="container">
          <Div className="row">
            <Div className="col-lg-5 col-xl-4">
              <SectionHeading
                title="UAE’s best agency"
                subtitle="Why Choose Us"
                btnText="Learn More"
                btnLink="/about"
              />
              <Spacing lg="45" md="45" />
            </Div>
            <Div className="col-lg-7 offset-xl-1">
              <Div className="cs-half_screen">
                <VideoModal
                  videoSrc="https://www.youtube.com/watch?v=9d26NVbGsZA"
                  bgUrl="/images/video_bg.webp"
                  variant="cs-style1 cs-size1"
                />
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      {/* End About Section */}

      {/* Start Testimonial Section */}
      <Spacing lg="145" md="80" />


      {/* Start LogoList Section */}
      <Div className="container">
        <SectionHeading
          title="Our reputed partner"
          subtitle="Top Clients"
          variant="cs-style1 text-center"
        />
        <Spacing lg="70" md="45" />
        <MovingText22 />
      </Div>
      <Spacing lg="150" md="80" />
      {/* End LogoList Section */}

      {/* Start CTA Section */}
      <Div className="container">
        <Cta
          title="Let’s disscuse make <br />something <i>cool</i> together"
          btnText="Contact Us"
          btnLink="/contact"
          bgSrc="/images/cta_bg.webp"
        />
      </Div>
      {/* End CTA Section */}
      <Spacing lg="80" md="40" />
    </>
  );
}

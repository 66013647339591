import React, { useEffect } from "react";
import Div from "../Div";
import MasonryGallery from "../Gallery/MasonryGallery";
import SectionHeading from "../SectionHeading";
import Spacing from "../Spacing";
import { pageTitle } from "../../helper";
import FunFact from "../FunFact";
import Hero6 from "../Hero/Hero6";
import MovingText from "../MovingText";
import ServiceList from "../ServiceList";
import MovingText2 from "../MovingLogo";
import {Helmet} from "react-helmet";

export default function PhotographyAgencyHome() {
  pageTitle("Home");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  // FunFact Data
  const funfaceData = [
    {
      title: "Years of History",
      factNumber: "16",
    },
    {
      title: "Projects Completed",
      factNumber: "650",
    },
    {
      title: "Happy Clients",
      factNumber: "230",
    },
    {
      title: "Services and more",
      factNumber: "15",
    },
  ];
  const heroSocialLinks = [
    {
      name: "Instagram",
      links: "https://www.instagram.com/maven.ae",
    },
    {
      name: "Facebook",
      links: "https://www.facebook.com/profile.php?id=61559003704097&mibextid=LQQJ4d",
    },
    {
      name: "YouTube",
      links: "https://www.youtube.com/channel/UCD-sZpls3rdmwedzap3yqEA",
    },
    {
      name: "TikTok",
      links: "https://www.tiktok.com/@maven.ae",
    },
    {
      name: "WhatsApp",
      links: "https://api.whatsapp.com/send?phone=971524867771",
    },

    
  ];

  const showcaseData = [
    // {
    //   title: "",
    //   imgUrl: "arino-react/public/images/a_homeimagesSlider/Sequence 2.mp4",
    //   href: "/case-study/case-study-details",
    //   type:'video'
    // },
    {
      title: "",
      imgUrl: "./images/a_homeimagesSlider/1.webp",
      href: "/case-study/case-study-details",
      type:'image'
    },
    {
      title: "",
      imgUrl: "/images/a_homeimagesSlider/8.webp",
      href: "/case-study/case-study-details",
      type:'image'
    },
    {
      title: "",
      imgUrl: "/images/a_homeimagesSlider/9.webp",
      href: "/case-study/case-study-details",
      type:'image'
    },
    {
      title: "",
      imgUrl: "/images/a_homeimagesSlider/10.webp",
      href: "/case-study/case-study-details",
      type:'image'
    },
    {
      title: "",
      imgUrl: "/images/a_homeimagesSlider/11.webp",
      href: "/case-study/case-study-details",
      type:'image'
    },
    {
      title: "",
      imgUrl: "/images/a_homeimagesSlider/2.webp",
      href: "/case-study/case-study-details",
      type:'image'
    },
    {
      title: "",
      imgUrl: "/images/a_homeimagesSlider/4.webp",
      href: "/case-study/case-study-details",
      type:'image'
    },
    {
      title: "",
      imgUrl: "/images/a_homeimagesSlider/5.webp",
      href: "/case-study/case-study-details",
      type:'image'
    },
    {
      title: "",
      imgUrl: "/images/a_homeimagesSlider/E2.webp",
      href: "/case-study/case-study-details",
      type:'image'
    },
    {
      title: "",
      imgUrl: "/images/a_homeimagesSlider/6.webp",
      href: "/case-study/case-study-details",
      type:'image'
    },

    {
      title: "",
      imgUrl: "/images/a_homeimagesSlider/LAND-4-4.webp",
      href: "/case-study/case-study-details",
      type:'image'
    },
    {
      title: "",
      imgUrl: "/images/misfotos/events.webp",
      href: "/case-study/case-study-details",
      type:'image'
    },

  ];
  return (
    <>
    {/* helmet */}
    <Helmet>

    <meta name="description" content="Maven Events Multimedia Company Home Page" />
    <meta
      name="keywords"
      content="Maven, Maven Events, photography,videography, digital marketing, social media Management,design,branding,graphic design, studio shoots, content creation, media services, portfolio, restaurants shoots, menu shoots, advertising management, website creation, social media marketing, photography studio"
    />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>
      {/* Start Hero Section */}
      <Hero6
        heroSocialLinks={heroSocialLinks}
        socialLinksHeading="Follow Us"
        showcaseData={showcaseData}
      />
      {/* End Hero Section */}

      {/* Start who we are Section */}
      <div className="container" style={{ paddingTop: "90px" }}>
        <FunFact
          variant="cs-type1"
          title="Who we are ?"
          subtitle="Maven Events is an event management company located in Abu Dhabi, UAE. Our founders have been in the industry for over 16 years and have worked throughout UAE and Canada providing events management and media services for over 120+ clients."
          data={funfaceData}
        />
      </div>
      {/* End FunFact Section */}

      {/* Start Services Section */}
      <Spacing lg="20" md="10" />
      <Div className="container">
        <SectionHeading
          title="Our core services"
          subtitle=""
          variant="cs-style1 text-center"
        />
        <Spacing lg="20" md="10" />
        <ServiceList />
      </Div>
      {/* End Services Section */}
      {/* End Portfolio Section */}
            {/* Start LogoList Section */}
            <Spacing lg="50" md="70" />
      {/* Start MovingText Section */}
      <MovingText text="Our reputed world wide partners" />
      {/* End MovingText Section */}
<MovingText2/>

      {/* End LogoList Section */}
      {/* Start Gallery Section */}
      <Spacing lg="80" md="40" />
      <MasonryGallery />
      <Spacing lg="150" md="80" />
      {/* End Gallery Section */}

    </>
  );
}

import React from 'react'
import { Link } from 'react-router-dom'
import Button from '../Button'
import Div from '../Div'
import './post.scss'

export default function PostStyle2({thumb, title, subtitle, date, category, categoryHref, href}) {
  return (
    <Div className="cs-post cs-style2">
      <Link to={href} className="cs-post_thumb cs-radius_15">
        
        <img src={thumb}  alt="Post" className=" cs-radius_15" style={{borderRadius:'15px', width:'1080px',height:'720px'}}/>
      </Link>
        <div style={{paddingLeft:"20px"}}>
      <Div className="cs-post_info">
        <h2 className="cs-post_title"><Link to={href}>{title}</Link></h2>
        <Div className="cs-post_sub_title">{subtitle}</Div>
        <Button btnLink={href} btnText='See More' />
      </Div>
        </div>
    </Div>
  )
}

import { Icon } from '@iconify/react';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { pageTitle } from '../../helper';
import Cta from '../Cta';
import PageHeading from '../PageHeading';
import Portfolio from '../Portfolio';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';
import axios from "axios";
import { Helmet } from "react-helmet";

export default function PortfolioPage() {
  pageTitle('Portfolio');
  const [data, setData] = useState([]);
  const [photo, setPhoto] = useState([]);
  const [active, setActive] = useState('all');
  const [itemShow, setItemShow] = useState(10);
  const fetchData = async () => {
    // Send an HTTP GET request to the specified URL
    const response = await axios.get("https://www.api.mavenevents.ae/api/event/");

    // Update the 'data' state with the data from the API response
    
    let events =[];
    for (let value of response.data) {
      events.push(
        {
          title: value.name,
          category: value.name,
          id:value.id
        }
      )
    }
    setData(events);
  };
  const fetchDataPhotography = async () => {
    // Send an HTTP GET request to the specified URL
    const response = await axios.get("https://www.api.mavenevents.ae/api/eventPhotography/");

    // Update the 'data' state with the data from the API response
    let photos =[];
    for (let value of response.data) {
      photos.push(
        {
          id:value.id,
          title: value.event.name,
          subtitle: '',
          href:  `/portfolio/${value.eventId}`,
          src: `https://www.api.mavenevents.ae/eventPhotography/${value.imageFile}`,
          category: value.event.name,
        }
      )
    }
    setPhoto(photos);
  };


  useEffect(() => {
    fetchData();
    fetchDataPhotography();
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
     {/* helmet */}
     <Helmet>
        <meta
          name="description"
          content="Maven Events - some recent work " 
        />
        <meta
          name="keywords"
          content="Maven Events, photography services, abu dhabi, storytelling, indoor photogoraphy, outdoor photography, Branding guideline, social media, social media management, brand, photography, videography"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
       {/* Page */}
      <PageHeading
        title="Portfolio"
        bgSrc="/images/misfotos/mavenPortofolio.webp"
        pageLinkText="Portfolio"
      />
      <Spacing lg="145" md="80" />
      <Div className="container">
        <Div className="cs-portfolio_1_heading">
          <SectionHeading title="Some recent work" subtitle="Our Portfolio" />
          <Div className="cs-filter_menu cs-style1">
            <ul className="cs-mp0 cs-center">
              <li className={active === 'all' ? 'active' : ''}>
                <span onClick={() => {setActive('all');setItemShow(10)}}>All</span>
              </li>
              {data.map((item, index) => (
                <li
                  className={active === item.category ? 'active' : ''}
                  key={index}
                >
                  <span onClick={() => {
                    setActive(item.category)
                    setItemShow(photo.length)
                  }}>
                    {item.title}
                  </span>
                </li>
              ))}
            </ul>
          </Div>
        </Div>
        <Spacing lg="90" md="45" />
        <Div className="row">
          {photo.slice(0, itemShow).map((item, index) => (
            <Div
              className={`${
                 'col-lg-4'
              } ${
                active === 'all'
                  ? ''
                  : !(active === item.category)
                  ? 'd-none'
                  : ''
              }`}
              key={index}
            >
              <Portfolio
                title={item.title}
                subtitle={item.subtitle}
                href={item.href}
                src={item.src}
                variant="cs-style1 cs-type1"
              />
              <Spacing lg="25" md="25" />
            </Div>
          ))}
        </Div>

        <Div className="text-center">
          {photo.length <= itemShow ? (
            ''
          ) : (
            <>
              <Spacing lg="65" md="40" />
              <span
                className="cs-text_btn"
                onClick={() => setItemShow(itemShow + 5)}
              >
                <span>Load More</span>
                <Icon icon="bi:arrow-right" />
              </span>
            </>
          )}
        </Div>
      </Div>
      <Spacing lg="145" md="80" />
      <Cta
        title="info@mavenevents.ae"
        bgSrc="/images/cta_bg_2.webp"
        variant="rounded-0"
      />
      <Spacing lg="80" md="40" />
    </>
  );
}

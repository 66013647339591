import React from 'react'
import { Icon } from '@iconify/react';

export default function ContactInfoWidget({withIcon, title}) {
  return (
    <>
      {title && <h2 className="cs-widget_title">{title}</h2>}
      <ul className="cs-menu_widget cs-style1 cs-mp0">
        <li>
          {withIcon?<span className='cs-accent_color'><Icon icon="material-symbols:person" /></span>:''}
          <a href='#'>
          Yasien - CEO/Founder
          </a>
        </li>
        <li>
          {withIcon?<span className='cs-accent_color'><Icon icon="material-symbols:person" /></span>:''}
          <a href='#'  >
          Zahra - Co-founder
          </a>
        </li>
        <li>
          {withIcon?<span className='cs-accent_color'><Icon icon="material-symbols:add-call-rounded" /></span>:''}
          <a href='tel:+971524867771' target='_blank' rel='noreferrer'>
          +971 52 486 7771 - Jarren - Office
          </a>
        </li>
        <li>
          {withIcon?<span className='cs-accent_color'><Icon icon="mdi:envelope" /></span>:''}
          <a href='mailto:info@mavenevents.ae' target='_blank' rel='noreferrer'>
          info@mavenevents.ae
          </a>
          
        </li>
        <li>
          {withIcon?<span className='cs-accent_color'><Icon icon="mdi:map-marker" /></span>:''}
          <a href='https://www.google.com/maps/place/Maven+Events/@24.4690027,54.3394311,15z/data=!4m2!3m1!1s0x0:0x61d253c1f9819134?sa=X&ved=2ahUKEwjsmKPkwI73AhWogv0HHfDpD8wQ_BJ6BAhAEAU' target='_blank' rel='noreferrer'>
          Maven Events, Burooj Tower M11, Abu Dhabi, UAE
          </a>
        </li>
      </ul>
    </>
  )
}

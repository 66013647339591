import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay } from 'swiper';
import Div from '../Div';
SwiperCore.use([Autoplay]);
export default function FullScreenHorizontalSlider({ data }) {
  return (
    <Div className="cs-swiper_arrow_style_1">
      <Swiper
        slidesPerView={1}
        spaceBetween={0}
        mousewheel={true}
        pagination={{
          clickable: true,
        }}
        speed={2800}
        loop={true}
        autoplay={{delay:900}}
        className="mySwiper"

      >
        {data.map((item, index) => (
          <SwiperSlide key={index}>
            {item.type === 'image' ? 
            <div
            className="cs-hero cs-style4 cs-bg"
            style={{ backgroundImage: `url(${item.imgUrl})`, }}

            alt='image'
          /> : 
          <div
          className="cs-hero cs-style4 cs-bg"
          style={{ backgroundImage: `url(${item.imgUrl})` }}

          alt='image'
        /> 
          // <video src='/images/a_homeimagesSlider/Sequence 2.mp4' autoPlay="true" loop='true' muted className='cs-hero cs-style4 cs-bg' style={{objectFit:'cover',padding:'0px'}}/>
          }
            
          </SwiperSlide>
        ))}
      </Swiper>
    </Div>
  );
}

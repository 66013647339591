import React from 'react'
import Div from '../Div'
import './movingImage.scss'
export default function MovingText({text}) {
  return (
    <Div className="cs-moving_text_wrap cs-bold cs-primary_font">
    <Div className="cs-moving_text_in">
 <div className='cs-moving_text'>
 <div ><img src={'/images/clientLogo/0.webp'} alt={'partnerLogo.alt'} className="cs-partner_logo" style={{width:'200px',height:'200px'}} /></div>
 <div ><img src={'/images/clientLogo/00.webp'} alt={'partnerLogo.alt'} className="cs-partner_logo" style={{width:'200px',height:'200px'}} /></div>
 <div ><img src={'/images/clientLogo/40.webp'} alt={'partnerLogo.alt'} className="cs-partner_logo" style={{width:'200px',height:'200px'}} /></div>
 <div ><img src={'/images/clientLogo/20.webp'} alt={'partnerLogo.alt'} className="cs-partner_logo"  style={{width:'200px',height:'200px'}}/></div>
 <div ><img src={'/images/clientLogo/52.webp'} alt={'partnerLogo.alt'} className="cs-partner_logo" style={{width:'200px',height:'200px'}}/></div>
 <div ><img src={'/images/clientLogo/3.webp'} alt={'partnerLogo.alt'} className="cs-partner_logo"  style={{width:'200px',height:'200px'}}/></div>
 <div ><img src={'/images/clientLogo/6.webp'} alt={'partnerLogo.alt'} className="cs-partner_logo"  style={{width:'200px',height:'200px'}}/></div>
 <div ><img src={'/images/clientLogo/28.webp'} alt={'partnerLogo.alt'} className="cs-partner_logo" style={{width:'200px',height:'200px'}} /></div>
 <div ><img src={'/images/clientLogo/17.webp'} alt={'partnerLogo.alt'} className="cs-partner_logo"  style={{width:'200px',height:'200px'}}/></div>
 <div ><img src={'/images/clientLogo/9.webp'} alt={'partnerLogo.alt'} className="cs-partner_logo"  style={{width:'200px',height:'200px'}}/></div>
 </div>

 <div className='cs-moving_text'>
 <div ><img src={'/images/clientLogo/0.webp'} alt={'partnerLogo.alt'} className="cs-partner_logo" style={{width:'200px',height:'200px'}} /></div>
 <div ><img src={'/images/clientLogo/00.webp'} alt={'partnerLogo.alt'} className="cs-partner_logo" style={{width:'200px',height:'200px'}} /></div>
 <div ><img src={'/images/clientLogo/40.webp'} alt={'partnerLogo.alt'} className="cs-partner_logo" style={{width:'200px',height:'200px'}} /></div>
 <div ><img src={'/images/clientLogo/20.webp'} alt={'partnerLogo.alt'} className="cs-partner_logo"  style={{width:'200px',height:'200px'}}/></div>
 <div ><img src={'/images/clientLogo/52.webp'} alt={'partnerLogo.alt'} className="cs-partner_logo" style={{width:'200px',height:'200px'}}/></div>
 <div ><img src={'/images/clientLogo/3.webp'} alt={'partnerLogo.alt'} className="cs-partner_logo"  style={{width:'200px',height:'200px'}}/></div>
 <div ><img src={'/images/clientLogo/6.webp'} alt={'partnerLogo.alt'} className="cs-partner_logo"  style={{width:'200px',height:'200px'}}/></div>
 <div ><img src={'/images/clientLogo/28.webp'} alt={'partnerLogo.alt'} className="cs-partner_logo" style={{width:'200px',height:'200px'}} /></div>
 <div ><img src={'/images/clientLogo/17.webp'} alt={'partnerLogo.alt'} className="cs-partner_logo"  style={{width:'200px',height:'200px'}}/></div>
 <div ><img src={'/images/clientLogo/9.webp'} alt={'partnerLogo.alt'} className="cs-partner_logo"  style={{width:'200px',height:'200px'}}/></div>
 </div>

    </Div>
  </Div>
  )
}

import React from "react";
import Div from "../Div";
import ContactInfoWidget from "../Widget/ContactInfoWidget";
import MenuWidget from "../Widget/MenuWidget";
import Newsletter from "../Widget/Newsletter";
import SocialWidget from "../Widget/SocialWidget";
import "./footer.scss";

export default function Footer({ copyrightText, logoSrc, logoAlt, text }) {
  const copyrightLinks = [
    {
      title: "Terms of Use",
      href: "#",
    },
    {
      title: "Privacy Policy",
      href: "#",
    },
  ];

  return (
    <footer className="cs-fooer">
      <Div className="cs-fooer_main">
        <Div className="container">
          <Div className="row">
            <Div className="col-lg-3 col-sm-6">
              <div className="cs-footer_item" style={{paddingTop:'-200px'}}>
                    <Div className="cs-text_widget">
                    <h2 className="cs-widget_title">{'We Are  '}</h2>
                      <img src={"images/Full-logo-01.webp"} alt={'Logo'}style={{marginTop:'-20px'}} />
                      <p>{"Let us give your next event the Maven Touch."}</p>
                    </Div>
              </div>
            </Div>
            <Div className="col-lg-1 col-sm-6"></Div>
            {/* <Div className="col-lg-3 col-sm-6">
              <Div className="cs-footer_item">
                <MenuWidget menuItems={serviceMenu} menuHeading='Services'/>
              </Div>
            </Div> */}
            <Div className="col-lg-4 col-sm-6">
              <Div className="cs-footer_item">
                <ContactInfoWidget title="Contact Us" withIcon />
              </Div>
            </Div>
            <Div className="col-lg-1 col-sm-6"></Div>
            <Div className="col-lg-3 col-sm-6">
              <Div className="cs-footer_item">
                <Newsletter
                  title="Join our community"
                  subtitle=""
                  placeholder="example@gmail.com"
                />
                <SocialWidget />
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      <Div className="container">
        <Div className="cs-bottom_footer">
          <Div className="cs-bottom_footer_left">
            <Div className="cs-copyright">
              Copyright © 2024 Maven Events, All Rights Reserved.
            </Div>
          </Div>
          <Div className="cs-bottom_footer_right">
            <MenuWidget menuItems={copyrightLinks} variant=" cs-style2" />
          </Div>
        </Div>
      </Div>
    </footer>
  );
}

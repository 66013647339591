import React, { useEffect } from 'react';
import { pageTitle } from '../../helper';
import Cta from '../Cta';
import Div from '../Div';
import PageHeading from '../PageHeading';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';
import { Helmet } from "react-helmet";

export default function CaseStudyDetailsPage() {
  pageTitle('Case Study');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
    {/* helmet */}
    <Helmet>
        <meta
          name="description"
          content="Maven Events - case study"
        />
        <meta
          name="keywords"
          content="influencer marketing, Maven Events, digital marketing, abu dhabi, storytelling, indoor photogoraphy, outdoor photography, market, Marketing strategy, SWOT analysis, Branding guideline"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <PageHeading
        title="Case Study Details"
        bgSrc="/images/misfotos/macencasestudy.webp"
        pageLinkText="Case Study"
      />
      <Spacing lg="145" md="80" />
      <Div className="container">
        <SectionHeading
          title="Importance of storytelling and influencer marketing in social media marketing"
          subtitle="Marketing"
          variant="cs-style1 text-center"
        />
        <Spacing lg="90" md="45" />
        <img
          src="/images/case_study_img_1.webp"
          alt="Thumb"
          className="w-100 cs-radius_15"
        />
        <Spacing lg="140" md="80" />
        <h2 className="cs-font_38 text-center">Case Study Overview</h2>
        <Spacing lg="60" md="45" />
        <p className="cs-m0">
        In today's highly competitive market, brands face the challenge of differentiating themselves and establishing meaningful connections with their target audience. For many businesses,
         effective storytelling and influencer marketing have emerged as powerful strategies to engage consumers, build brand loyalty, and drive business growth. This case study explores how a
          prominent beauty brand partnered with our agency to enhance its online presence and connect with its audience through strategic storytelling and influencer collaborations in social media marketing.
        </p>
        <Spacing lg="65" md="45" />
        <Div className="row">
          <Div className="col-sm-6">
            <img
              src="/images/case_study_img_2.webp"
              alt="Thumb"
              className="w-100 cs-radius_5"
            />
            <Spacing lg="25" md="25" />
          </Div>
          <Div className="col-sm-6">
            <img
              src="/images/case_study_img_3.webp"
              alt="Thumb"
              className="w-100 cs-radius_5"
            />
            <Spacing lg="25" md="25" />
          </Div>
        </Div>
        <Spacing lg="125" md="55" />
      </Div>
      <Div className="cs-gradient_bg_1 cs-shape_wrap_6">
        <Div className="cs-shape_2"></Div>
        <Div className="cs-shape_2"></Div>
        <Div className="container">
          <Spacing lg="145" md="80" />
          <h2 className="cs-font_38 text-center">Case Study Research</h2>
          <Spacing lg="90" md="45" />
          <Div className="row align-items-center cs-column_reverse_lg">
            <Div className="col-lg-5">
              <h3 className="cs-font_30 cs-m0">Marketing strategy</h3>
              <Spacing lg="45" md="30" />
              <p className="cs-m0">
              In our quest to formulate a robust marketing strategy for our client, we embarked on a thorough research journey,
               delving into various facets crucial to their success. Central to our endeavor was the meticulous analysis of their target audience,
                which involved dissecting demographic data and exploring consumer behaviors and preferences. Additionally, we closely monitored industry trends,
                 scrutinizing market dynamics and consumer sentiment to unearth emerging opportunities and challenges within the beauty sector. To gain a competitive edge,
                  we meticulously examined the strategies and positioning of key rivals, identifying whitespace for our client to occupy and opportunities for differentiation. <br/><br/>
                   This introspective process also entailed a comprehensive audit of our client's brand, mapping out its strengths, weaknesses, and distinct value propositions through 
                   a SWOT analysis. Moreover, we engaged directly with consumers, extracting invaluable insights through surveys, focus groups, and social listening tools to grasp their
                    perceptions and expectations. This holistic approach extended to evaluating cutting-edge technologies and marketing platforms pertinent to the beauty landscape,
                     ensuring our recommendations remained at the forefront of innovation. Anchored by a deep understanding of regulatory standards and budget considerations, our 
                     research laid the groundwork for a tailored marketing strategy, 
              meticulously crafted to propel our client towards their objectives with precision and efficacy.
              </p>
            </Div>
            <Div className="col-lg-6 offset-lg-1 text-center">
              <Div className="cs-portfolio_img_in cs-shine_hover_1 rounded-circle">
                <img
                  src="/images/case_study_img_4.webp"
                  alt="Case study"
                  className="w-100"
                />
              </Div>
              <Spacing lg="0" md="40" />
            </Div>
          </Div>
          <Spacing lg="100" md="80" />
          <Div className="row align-items-center">
            <Div className="col-lg-6 text-center">
              <Div className="cs-portfolio_img_in cs-shine_hover_1 rounded-circle">
                <img
                  src="/images/case_study_img_5.webp"
                  alt="Case study"
                  className="w-100"
                />
              </Div>
              <Spacing lg="0" md="40" />
            </Div>
            <Div className="col-lg-5 offset-lg-1">
              <h3 className="cs-font_30 cs-m0">Branding guideline</h3>
              <Spacing lg="45" md="30" />
              <p className="cs-m0">
              As we embarked on developing a comprehensive marketing strategy, a pivotal aspect of our research focused on establishing
               clear branding guidelines to fortify our client's identity and messaging. This endeavor involved an in-depth exploration of their brand ethos,
                values, and desired positioning in the market. We meticulously scrutinized existing brand assets, including logos, color palettes, typography, and imagery,
                 to ensure consistency and cohesion across all touchpoints. Additionally, we conducted a competitive analysis to benchmark our 
                 client's branding against industry leaders and identify opportunities for differentiation. Our research extended to examining best practices
                  and trends in branding across various sectors, drawing inspiration from successful case studies and expert insights.<br/> <br/> Furthermore, 
                  we engaged stakeholders within the organization to understand their vision for the brand and align our recommendations accordingly.
                  By synthesizing these findings, we formulated a set of comprehensive branding guidelines encompassing visual elements, tone of voice, messaging frameworks,
                   and brand personality traits. These guidelines serve as a roadmap for maintaining brand integrity and coherence in all communication channels,
               empowering our client to forge deeper connections with their audience and carve out a distinctive identity in the marketplace.
              </p>
            </Div>
          </Div>
          <Spacing lg="150" md="80" />
        </Div>
      </Div>
      <Spacing lg="140" md="80" />
      <Div className="container text-center">
        <Div className="row col-lg-10 offset-lg-1">
          <h2 className="cs-font_38 cs-m0">Result of The Case Study</h2>
          <Spacing lg="60" md="45" />
          <p className="cs-m0">
           
Through our diligent research, we developed comprehensive branding guidelines tailored to our 
client's identity and messaging needs. This involved analyzing their brand ethos, values, and desired positioning in the market. 
We ensured consistency across all touchpoints by reviewing existing brand assets such as logos, color palettes, typography, and imagery.
 Additionally, we conducted a competitive analysis to benchmark our client's branding against industry leaders and identify areas for improvement.
  Drawing inspiration from successful case studies and expert insights, we crafted guidelines encompassing visual elements, tone of voice, messaging 
  frameworks, and brand personality traits. These guidelines provide a strategic roadmap for maintaining brand integrity and coherence across all communication
channels. With these guidelines in place, our client is well-equipped to establish a distinctive and memorable presence in the marketplace.
          </p>
        </Div>
      </Div>
      <Spacing lg="80" md="40" />
      {/* Start CTA Section */}
      <Div className="container">
        <Cta
          title="Let’s disscuse make <br />something <i>cool</i> together"
          btnText="Contact Us"
          btnLink="/contact"
          bgSrc="/images/cta_bg.webp"
        />
      </Div>
      <Spacing lg="145" md="80" />
      {/* End CTA Section */}
    </>
  );
}

import React from 'react'
import { useState } from 'react'
import Div from '../Div'

export default function Accordion() {
  const [selected, setSelected] = useState(0)
  const handelToggle = (index) => {
    if(selected === index) {
      return setSelected(null)
    }
    setSelected(index)
  }
  const accordionData = [
    {
      question: 'What types of events does your company specialize in managing?',
      answer: " Our company specializes in managing a wide range of events including corporate conferences, weddings, product launches, seminars, fundraisers, and private parties." },
    {
      question: ' Do you provide both indoor and outdoor photography coverage?',
      answer: ' Yes, our photographers are skilled in capturing high-quality images in both indoor and outdoor settings. We adjust our equipment and techniques to suit the specific lighting and ambiance of each location.'
    },
    {
      question: 'Can we request specific styles or themes for event photography?',
      answer: 'Absolutely! We welcome your input and can tailor our photography style to match your event theme or preferences. Whether you prefer classic, candid, or artistic shots, we strive to capture the essence of your event.'
    },{
      question: 'Which social media platforms do you manage?',
      answer: 'We manage a range of social media platforms including Facebook, Instagram, LinkedIn, WhatsApp, depending on the target audience and nature of the market.'
    },
    {
      question: 'What digital marketing strategies do you employ to promote events?',
      answer: 'Our digital marketing strategies include targeted email marketing, search engine optimization (SEO), pay-per-click (PPC) advertising, content marketing, and influencer collaborations to reach your desired audience and drive ticket sales or event registrations.'
    },
    {
      question: 'Content Creation and Management?',
      answer: "Yes, we offer comprehensive content creation services tailored to each client's unique brand identity and messaging. Our team of experienced content creators develops engaging and relevant content for social media posts, blog articles, email newsletters, ad copy, and more. We welcome client input and collaborate closely to ensure that the content aligns with their vision and goals."
    },
    {
      question: 'How do you target specific audiences through digital marketing?',
      answer: 'We employ demographic targeting, interest-based targeting, retargeting, and lookalike audience targeting strategies to ensure that your event promotions reach the right audience segments with relevant messaging.'
    },
    {
      question: 'Client Collaboration and Communication?',
      answer: "We believe in open and transparent communication with our clients throughout the entire process. We work closely with clients to understand their business objectives, preferences, and feedback. We utilize various communication channels including email, phone calls, video conferencing, and project management tools to keep clients informed and involved in the decision-making process. Clients are encouraged to provide input, request changes, and ask questions at any stage of the project."
    },
  ]
  return (
    <Div className="cs-accordians cs-style1">
      {accordionData.map((item, index)=>(
        <Div className={`cs-accordian ${selected===index?'active':''}`} key={index}>
          <Div className="cs-accordian_head" onClick={()=>handelToggle(index)}>
            <h2 className="cs-accordian_title" style={{color:'#B9B9B9'}}>{item.question}</h2>
            <span className="cs-accordian_toggle cs-accent_color">
              <svg width={15} height={8} viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 0L7.5 7.5L15 0H0Z" fill="currentColor" />
              </svg>                    
            </span>
          </Div>
          <Div className='cs-accordian_body'>
            <Div className="cs-accordian_body_in" style={{color:'#ffffff'}}>{item.answer}</Div>
            </Div>
        </Div>
      ))}
    </Div>
  )
}

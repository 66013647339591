import React from 'react'
import './team.scss'
import { Link } from 'react-router-dom';
import Div from '../Div';

export default function Career({memberImage, memberName, memberDesignation,id}) {
  return (
    <Div className="cs-team cs-style1">
      <Link to={`/career/careerDetail/${id}`}>
        <Div className="cs-member_thumb">
          <img src={memberImage} alt={'img'} />
          <Div className="cs-member_overlay" />
        </Div>
        <Div className="cs-member_info">
          <div style={{textAlign:'center'}}>
          <div className="cs-member_designation" style={{color:'white'}}>{memberDesignation}</div>
          </div>
        </Div>
        </Link>
      </Div>
  )
}

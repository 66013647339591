import React, { useEffect, useState } from "react";
import { pageTitle } from "../../helper";
import Cta from "../Cta";
import PageHeading from "../PageHeading";
import PostStyle2 from "../Post/PostStyle2";
import Div from "../Div";
import Spacing from "../Spacing";
import axios from "axios";
import { Pagination } from "@mui/material";
import usePagination from "./PaginationFunc";
import { Helmet } from "react-helmet";

export default function BlogPage() {
  pageTitle("Blog");
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };
  const PER_PAGE = 2;
  const count = Math.ceil(data.length / PER_PAGE);
  const _DATA = usePagination(data, PER_PAGE);
  const fetchData = async () => {
    // Send an HTTP GET request to the specified URL
    const response = await axios.get("https://www.api.mavenevents.ae/api/blog");
    // Update the 'data' state with the data from the API response
    const blogs = [];

    for (let value of response.data) {
      blogs.push({
        thumb: value.imageFile ? `https://www.api.mavenevents.ae/blog/${value.imageFile}` : '/images/a_deTodo/mavenblog.webp',
        title: value.title,
        subtitle: value.description,
        date: value.createdAt.split("T")[0],
        category: "",
        categoryHref: "",
        href: `/blog/${value.id}`,
        id: value.id,
      });
    }
    setData(blogs);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData();
  }, []);
  return (
    <>
    {/* helmet */}
    <Helmet>
        <meta
          name="description"
          content="Maven Events - Our Blogs"
        />
        <meta
          name="keywords"
          content="Maven Events, digital marketing, abu dhabi, storytelling, indoor photogoraphy, outdoor photography, market, Marketing strategy, SWOT analysis, Branding guideline, social media ads, advertisement, social media, social media management, brand, photography"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <PageHeading
        title="Our Blogs"
        bgSrc="/images/misfotos/wallpaperflare.webp"
        pageLinkText="Blogs"
      />
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-lg-2"></Div>
          <Div className="col-lg-8">
            {_DATA.currentData().map((item) => {
              return (
                <Div key={item.id}>
                  <PostStyle2
                    thumb={item.thumb}
                    title={item.title}
                    subtitle={item.subtitle}
                    date={item.date}
                    category={item.category}
                    categoryHref={item.categoryHref}
                    href={item.href}
                  />
                  <Spacing lg="95" md="60" />
                  {data.length > item + 1 && <Spacing lg="95" md="60" />}
                </Div>
              );
            })}
             <div style={{display:'flex',justifyContent:'center',paddingBottom:'30px',paddingRight:'30px'}}>
            <Pagination
              count={count}
              style={{alignItems:'center', alignContent:'center',justifyContent:'center'}}
              size="large"
              page={page}
              color="standard"
              variant="outlined"
              shape="rounded"
              onChange={handleChange}
            />
            </div>
          </Div>
          <Div className="col-xl-3 col-lg-4 offset-xl-1">
            <Spacing lg="0" md="80" />
          </Div>
        </Div>
      </Div>
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Cta
          title="Let’s disscuse make <br />something <i>cool</i> together"
          btnText="Contact Us"
          btnLink="/contact"
          bgSrc="/images/cta_bg.webp"
        />
      </Div>
      <Spacing lg="80" md="40" />
    </>
  );
}

import React from 'react'
import './client.scss'

import Div from '../Div';

export default function Career({memberImage, memberName, memberDesignation,id,loading}) {
  return (
    <Div className="cs-team cs-style1">
      
        <div className="cs-member_thumb pl-40" style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
          <img src={memberImage} alt={'img'} style={{alignItems:'center'}} className='img_client' loading={loading} />
          <Div className="cs-member_overlay_client " />
        </div>
        <Div className="cs-member_info">
          <div style={{textAlign:'center'}}>
          <div className="cs-member_designation" style={{color:'white'}}>{memberDesignation}</div>
          </div>
        </Div>

      </Div>
  )
}

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { pageTitle } from "../../helper";
import Cta from "../Cta/index2";
import PageHeading from "../PageHeading";
import Div from "../Div";
import Spacing from "../Spacing";
import axios from "axios";
import Portfolio from "../Portfolio/index2";
import { Helmet } from "react-helmet";

export default function PortfolioDetailsPage() {
  pageTitle("Portfolio Details");
  const params = useParams();
  const { portfolioId } = params;
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const fetchData = async () => {
    // Send an HTTP GET request to the specified URL
    const response = await axios.get(
      `https://www.api.mavenevents.ae/api/event/${portfolioId}`
    );
    // Update the 'data' state with the data from the API response
    setData(response.data);
  };
  const fetchData2 = async () => {
    // Send an HTTP GET request to the specified URL
    const response = await axios.get(
      `https://www.api.mavenevents.ae/api/eventPhotography/event/${portfolioId}`
    );
    // Update the 'data' state with the data from the API response
    let photos = [];
    for (let value of response.data) {
      photos.push({
        id: value.id,
        title: value.event.name,
        subtitle: "",
        href: `/portfolio/${value.eventId}`,
        src: `https://www.api.mavenevents.ae/eventPhotography/${value.imageFile}`,
        category: value.event.name,
      });
    }
    setData2(photos);
  };
  // eslint-disable-next-line 
  useEffect(() => {
    fetchData();
    fetchData2();
    window.scrollTo(0, 0);
    // eslint-disable-next-line 
  }, []);
  return (
    <>
    {/* helmet */}
    <Helmet>
        <meta
          name="description"
          content="Maven Events - our recent session " 
        />
        <meta
          name="keywords"
          content="Maven Events, photography services, abu dhabi, storytelling, indoor photogoraphy, outdoor photography, Branding guideline, social media, social media management, brand, photography, videography"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
       {/* Page */}
      <PageHeading
        title={data.name + " Portfolio"}
        bgSrc="/images/service_hero_bg.webp"
        pageLinkText={"Portfolio " + data.id}
      />
      <Spacing lg="150" md="80" />
      <Div className="container">
       { data.coverImageFile&& <img
          src={`https://www.api.mavenevents.ae/event/${data.coverImageFile}`}
          alt="Details"
          className="cs-radius_15 w-100"
        />}
        <Spacing lg="80" md="40" />
        <Div className="row">
          <Div className="container">
                <Div className="cs-portfolio_1_heading"></Div>
                <Div className="row">
                  {data2.map((item, index) => (
                    <Div
                      className={`${
 "col-lg-4"
                      } `}
                      key={index}
                    >
                      <Portfolio
                        title={item.title}
                        subtitle={item.subtitle}
                        href={item.href}
                        src={item.src}
                        variant="cs-style1 cs-type1"
                      />
                      <Spacing lg="25" md="25" />
                    </Div>
                  ))}
                </Div>
              </Div>
        </Div>
        <Spacing lg="40" md="20" />
      </Div>
      <Cta
        title="Want to see more?<br/> Check out our instagram"
        bgSrc="/images/cta_bg_2.webp"
        variant="rounded-0"
        btnLink={'https://www.instagram.com/maven.ae'}
        btnText={'Maven Instagram'}
      />
      <Spacing lg="80" md="40" />
    </>
  );
}

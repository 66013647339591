import React from "react";
import { Icon } from "@iconify/react";
import Div from "../Div";

export default function SocialWidget() {
  return (
    <Div className="cs-social_btns cs-style1">
      <a
        href="https://api.whatsapp.com/send?phone=971524867771"
        target="_blank"
        rel="noreferrer"
        className="cs-center"
        aria-label="social media link"
      >
        <Icon icon="fa6-brands:whatsapp" />
      </a>
      <a href="https://www.youtube.com/channel/UCD-sZpls3rdmwedzap3yqEA" target="_blank" rel="noreferrer" className="cs-center"aria-label="social media link">
        <Icon icon="fa6-brands:youtube" />
      </a>
      <a href="https://old.mavenevents.ae/mavenqr" target="_blank" rel="noreferrer" className="cs-center"aria-label="social media link">
        <Icon icon="bx:qr" />
      </a>
      <a href="https://www.tiktok.com/@maven.ae" target="_blank" rel="noreferrer" className="cs-center"aria-label="social media link">
        <Icon icon="fa6-brands:tiktok" />
      </a>
      <a href="https://www.facebook.com/profile.php?id=61559003704097&mibextid=LQQJ4d" target="_blank" rel="noreferrer" className="cs-center"aria-label="social media link">
        <Icon icon="fa6-brands:facebook" />
      </a>
      <a href="https://www.instagram.com/maven.ae" target="_blank" rel="noreferrer" className="cs-center"aria-label="social media link">
        <Icon icon="fa6-brands:instagram" />
      </a>
    </Div>
  );
}

import { Icon } from "@iconify/react";
import React, { useState , useRef } from "react";
import Div from "../Div";
import SectionHeading from "../SectionHeading";
import Spacing from "../Spacing";
import ModalImage from "react-modal-image";

export default function MasonryGallery() {
  const [active, setActive] = useState("all");
  const [itemShow, setItemShow] = useState(4);
const [loadMore, setloadMore] = useState(true);
const myRef = useRef(null);
  const portfolioData = [
        {
      src: "/images/a_aa_feeds/8.webp",
      srcLg: "/images/a_aa_feeds/8.webp",
      category: "Cafe",
      height: 622,
    },
    {
      src: "/images/a_aa_feeds/56.webp",
      srcLg: "/images/a_aa_feeds/56.webp",
      category: "Wedding",
      height: 622,
    },


    {
      src: "/images/a_aa_feeds/48.webp",
      srcLg: "/images/a_aa_feeds/48.webp",
      category: "SPA & Clinics",
      height: 622,
    },
    {
      src: "/images/a_aa_feeds/3.webp",
      srcLg: "/images/a_aa_feeds/3.webp",
      category: "Business",
      height: 622,
    },
    {
      src: "/images/a_aa_feeds/1.webp",
      srcLg: "/images/a_aa_feeds/1.webp",
      category: "Business",
      height: 622,
    },
    {
      src: "/images/a_aa_feeds/2.webp",
      srcLg: "/images/a_aa_feeds/2.webp",
      category: "Business",
      height: 622,
    },

    {
      src: "/images/a_aa_feeds/4.webp",
      srcLg: "/images/a_aa_feeds/4.webp",
      category: "Business",
      height: 622,
    },

    {
      src: "/images/a_aa_feeds/5.webp",
      srcLg: "/images/a_aa_feeds/5.webp",
      category: "Business",
      height: 622,
    },

    {
      src: "/images/a_aa_feeds/6.webp",
      srcLg: "/images/a_aa_feeds/6.webp",
      category: "Business",
      height: 622,
    },
    {
      src: "/images/a_aa_feeds/7.webp",
      srcLg: "/images/a_aa_feeds/7.webp",
      category: "Business",
      height: 622,
    },

    {
      src: "/images/a_aa_feeds/9.webp",
      srcLg: "/images/a_aa_feeds/9.webp",
      category: "Cafe",
      height: 622,
    },

    {
      src: "/images/a_aa_feeds/10.webp",
      srcLg: "/images/a_aa_feeds/10.webp",
      category: "Cafe",
      height: 622,
    },
    {
      src: "/images/a_aa_feeds/11.webp",
      srcLg: "/images/a_aa_feeds/11.webp",
      category: "Cafe",
      height: 622,
    },

    {
      src: "/images/a_aa_feeds/12.webp",
      srcLg: "/images/a_aa_feeds/12.webp",
      category: "Cafe",
      height: 622,
    },
    {
      src: "/images/a_aa_feeds/13.webp",
      srcLg: "/images/a_aa_feeds/13.webp",
      category: "Restautants",
      height: 622,
    },
    {
      src: "/images/a_aa_feeds/16.webp",
      srcLg: "/images/a_aa_feeds/16.webp",
      category: "Restautants",
      height: 622,
    },
    {
      src: "/images/a_aa_feeds/17.webp",
      srcLg: "/images/a_aa_feeds/17.webp",
      category: "Restautants",
      height: 622,
    },

    {
      src: "/images/a_aa_feeds/18.webp",
      srcLg: "/images/a_aa_feeds/18.webp",
      category: "Cafe",
      height: 622,
    },
    {
      src: "/images/a_aa_feeds/19.webp",
      srcLg: "/images/a_aa_feeds/19.webp",
      category: "Cafe",
      height: 622,
    },
    {
      src: "/images/a_aa_feeds/20.webp",
      srcLg: "/images/a_aa_feeds/20.webp",
      category: "Cafe",
      height: 622,
    },
    {
      src: "/images/a_aa_feeds/21.webp",
      srcLg: "/images/a_aa_feeds/21.webp",
      category: "Cafe",
      height: 622,
    },
    {
      src: "/images/a_aa_feeds/22.webp",
      srcLg: "/images/a_aa_feeds/22.webp",
      category: "Cafe",
      height: 622,
    },
    {
      src: "/images/a_aa_feeds/23.webp",
      srcLg: "/images/a_aa_feeds/23.webp",
      category: "Cafe",
      height: 622,
    },
    {
      src: "/images/a_aa_feeds/24.webp",
      srcLg: "/images/a_aa_feeds/24.webp",
      category: "Restautants",
      height: 622,
    },
    {
      src: "/images/a_aa_feeds/25.webp",
      srcLg: "/images/a_aa_feeds/25.webp",
      category: "Cafe",
      height: 622,
    },
    {
      src: "/images/a_aa_feeds/26.webp",
      srcLg: "/images/a_aa_feeds/26.webp",
      category: "Restautants",
      height: 622,
    },

    {
      src: "/images/a_aa_feeds/27.webp",
      srcLg: "/images/a_aa_feeds/27.webp",
      category: "Restautants",
      height: 622,
    },

    {
      src: "/images/a_aa_feeds/28.webp",
      srcLg: "/images/a_aa_feeds/28.webp",
      category: "Restautants",
      height: 622,
    },

    {
      src: "/images/a_aa_feeds/29.webp",
      srcLg: "/images/a_aa_feeds/29.webp",
      category: "Cafe",
      height: 622,
    },

    {
      src: "/images/a_aa_feeds/30.webp",
      srcLg: "/images/a_aa_feeds/30.webp",
      category: "Cafe",
      height: 622,
    },

    {
      src: "/images/a_aa_feeds/31.webp",
      srcLg: "/images/a_aa_feeds/31.webp",
      category: "Restautants",
      height: 622,
    },

    {
      src: "/images/a_aa_feeds/32.webp",
      srcLg: "/images/a_aa_feeds/32.webp",
      category: "Restautants",
      height: 622,
    },

    {
      src: "/images/a_aa_feeds/33.webp",
      srcLg: "/images/a_aa_feeds/33.webp",
      category: "Restautants",
      height: 622,
    },

    {
      src: "/images/a_aa_feeds/34.webp",
      srcLg: "/images/a_aa_feeds/34.webp",
      category: "Restautants",
      height: 622,
    },

    {
      src: "/images/a_aa_feeds/35.webp",
      srcLg: "/images/a_aa_feeds/35.webp",
      category: "Restautants",
      height: 622,
    },

    {
      src: "/images/a_aa_feeds/36.webp",
      srcLg: "/images/a_aa_feeds/36.webp",
      category: "Restautants",
      height: 622,
    },

    {
      src: "/images/a_aa_feeds/37.webp",
      srcLg: "/images/a_aa_feeds/37.webp",
      category: "Restautants",
      height: 622,
    },

    {
      src: "/images/a_aa_feeds/38.webp",
      srcLg: "/images/a_aa_feeds/38.webp",
      category: "Restautants",
      height: 622,
    },

    {
      src: "/images/a_aa_feeds/39.webp",
      srcLg: "/images/a_aa_feeds/39.webp",
      category: "Restautants",
      height: 622,
    },

    {
      src: "/images/a_aa_feeds/40.webp",
      srcLg: "/images/a_aa_feeds/40.webp",
      category: "Restautants",
      height: 622,
    },

    {
      src: "/images/a_aa_feeds/41.webp",
      srcLg: "/images/a_aa_feeds/41.webp",
      category: "Restautants",
      height: 622,
    },

    {
      src: "/images/a_aa_feeds/42.webp",
      srcLg: "/images/a_aa_feeds/42.webp",
      category: "Cafe",
      height: 622,
    },

    {
      src: "/images/a_aa_feeds/43.webp",
      srcLg: "/images/a_aa_feeds/43.webp",
      category: "SPA & Clinics",
      height: 622,
    },

    {
      src: "/images/a_aa_feeds/44.webp",
      srcLg: "/images/a_aa_feeds/44.webp",
      category: "SPA & Clinics",
      height: 622,
    },

    {
      src: "/images/a_aa_feeds/45.webp",
      srcLg: "/images/a_aa_feeds/45.webp",
      category: "SPA & Clinics",
      height: 622,
    },

    {
      src: "/images/a_aa_feeds/46.webp",
      srcLg: "/images/a_aa_feeds/46.webp",
      category: "SPA & Clinics",
      height: 622,
    },

    {
      src: "/images/a_aa_feeds/47.webp",
      srcLg: "/images/a_aa_feeds/47.webp",
      category: "SPA & Clinics",
      height: 622,
    },



    {
      src: "/images/a_aa_feeds/49.webp",
      srcLg: "/images/a_aa_feeds/49.webp",
      category: "SPA & Clinics",
      height: 622,
    },

    {
      src: "/images/a_aa_feeds/50.webp",
      srcLg: "/images/a_aa_feeds/50.webp",
      category: "SPA & Clinics",
      height: 622,
    },

    {
      src: "/images/a_aa_feeds/51.webp",
      srcLg: "/images/a_aa_feeds/51.webp",
      category: "SPA & Clinics",
      height: 622,
    },

    {
      src: "/images/a_aa_feeds/52.webp",
      srcLg: "/images/a_aa_feeds/52.webp",
      category: "SPA & Clinics",
      height: 622,
    },

    {
      src: "/images/a_aa_feeds/53.webp",
      srcLg: "/images/a_aa_feeds/53.webp",
      category: "SPA & Clinics",
      height: 622,
    },

    {
      src: "/images/a_aa_feeds/54.webp",
      srcLg: "/images/a_aa_feeds/54.webp",
      category: "Business",
      height: 622,
    },

    {
      src: "/images/a_aa_feeds/55.webp",
      srcLg: "/images/a_aa_feeds/55.webp",
      category: "Wedding",
      height: 622,
    },  
  ];
  const categoryMenu = [

    {
      title: "SPA & Clinics",
      category: "SPA & Clinics",
    },
    {
      title: "Wedding",
      category: "Wedding",
    },
    {
      title: "Cafe",
      category: "Cafe",
    },
    {
      title: "Restautants",
      category: "Restautants",
    },

    {
      title: "Business",
      category: "Business",
    },
  ];
  return (
    <>
      <Div className="container">
        <Div className="cs-portfolio_1_heading">
          <SectionHeading title="Some recent work" subtitle="Our Portfolio" />
          <Div className="cs-filter_menu cs-style1">
            <ul className="cs-mp0 cs-center">
              <li className={active === "all" ? "active" : ""}>
                <span
                  onClick={() => {
                    setActive("all");
                    setItemShow(12);
                  }}
                >
                  All
                </span>
              </li>
              {categoryMenu.map((item, index) => (
                <li
                  className={active === item.category ? "active" : ""}
                  key={index}
                >
                  <span
                    onClick={() => {
                      setActive(item.category);
                      setItemShow(portfolioData.length);
                    }}
                  >
                    {item.title}
                  </span>
                </li>
              ))}
            </ul>
          </Div>
        </Div>
      </Div>
      <Spacing lg="90" md="45" />
      <section ref={myRef} id="my-section">
      </section>
      <Div className="cs-masonry_4_col">
        {portfolioData.slice(0, itemShow).map((item, index) => (
          <Div
            className={`${
              active === "all"
                ? ""
                : !(active === item.category)
                ? "d-none"
                : ""
            }`}
            key={index}
          >
            <Div
              className="cs-portfolio cs-style1 cs-type2"
              style={{ height: `${item.height}px`, "objectFit": "scale-down" }}
            >
              <Div className="cs-lightbox_item">
                <ModalImage
                loading="lazy"
                  style={{ objectFit: "fill " }}
                  small={item.src}
                  large={item.srcLg}
                  alt={'portfolio'}
                  hideDownload={true}
                />
              </Div>
              <Div className="" />
              <Div
                className="cs-portfolio_bg cs-bg"
                style={{ backgroundImage: `url("${item.src}")` }}
              />

            </Div>
          </Div>
        ))}
      </Div>
      <Div className="container">
        <Div className="text-center">
          {portfolioData.length <= itemShow ? (
            ""
          ) : (




            <>
              <Spacing lg="65" md="40" />
              {/* <span
                className="cs-text_btn"
                // onClick={() => setItemShow(itemShow + 8)
                onClick={() => setloadMore(true )
                }
              >
                <span style={{color:'#f4b231'}}>Load More</span>
                <Icon icon="bi:arrow-right" style={{color:'#f4b231'}} />
              </span> */}


{
  loadMore === true ? (              
  <Div className="cs-filter_menu cs-style2">
  <ul className="cs-mp0 cs-center">
    <li className={active === "all" ? "active" : ""}>
      <span
        onClick={() => {
          setActive("all");
          setItemShow(itemShow + 8);
          setloadMore(true )
        }}
      >
        load more&nbsp;
      </span>
      <Icon icon="bi:arrow-right" style={{color:'#f4b231'}} />
    </li>
    {categoryMenu.map((item, index) => (
      <li
        className={active === item.category ? "active" : ""}
        key={index}
      >
        <span
          onClick={() => {
            setActive(item.category);
            setItemShow(portfolioData.length);
            setloadMore(true)
            myRef.current?.scrollIntoView()
          }}
        >
          {item.title}
        </span>
      </li>
    ))}
  </ul>
</Div>

) : ("")

}








            </>






          )}
        </Div>
      </Div>
    </>
  );
}

import React, { useEffect, useState } from 'react';
import { pageTitle } from '../../helper';
import Cta from '../Cta';
import PageHeading from '../PageHeading';
import Div from '../Div';
import Spacing from '../Spacing';
import Career from '../Career';
import axios from "axios";
import { Helmet } from "react-helmet";

export default function CareerPage() {
  pageTitle('Career');
  const [data, setData] = useState([]);
  const fetchData = async () => {
    // Send an HTTP GET request to the specified URL
    const response = await axios.get("https://www.api.mavenevents.ae/api/careers/website");
    // Update the 'data' state with the data from the API response
    const jobs = [];

    for (let value of response.data) {
      jobs.push(
        {
          memberImage: '/images/misfotos/MAVENjob.webp',
          memberName: '',
          memberDesignation: value.position,
          id:value.id
        }
      )
    }
    setData(jobs);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData();
  }, []);

  return (
    <>
        {/* helmet */}
        <Helmet>
        <meta
          name="description"
          content="Maven Events - Be part of our creative team"
        />
        <meta
          name="keywords"
          content=" Maven Events, jobs, abu dhabi, our team, marketing agency, designer, business, photographer, videographer, graphic designer, content creator, digital marketing"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <PageHeading
        title="Careers"
        bgSrc="/images/misfotos/maveCareer.webp"
        pageLinkText="Career"
      />
      <Spacing lg="145" md="80" />
      <Div className="container">

        <Div className="row">
          {data.map((item, index) => (
            <Div key={index} className="col-lg-3 col-sm-6">
              <Career
                memberImage={item.memberImage}
                memberName={item.memberName}
                memberDesignation={item.memberDesignation}
                memberSocial={item.memberSocial}
                id={item.id}
              />
              <Spacing lg="80" md="30" />
            </Div>
          ))}
        </Div>

        <Spacing lg="90" md="45" />
        <Cta
            title="Discover rewarding career <br/> opportunities with Us"
            btnText=""
            btnLink=""
            bgSrc="/images/cta_bg.webp"
          />
        <Spacing lg="90" md="45" />
      </Div>
    </>
  );
}

import React, { useEffect, useState } from "react";
import { pageTitle } from "../../helper";
import Cta from "../Cta";
import PageHeading from "../PageHeading";
import Div from "../Div";
import Spacing from "../Spacing";
import parse from "html-react-parser";
import PostSlider from "../Slider/PostSlider";
import axios from "axios";
import { Helmet } from "react-helmet";

export default function AboutPage() {
  pageTitle("About");
  const [data, setData] = useState([]);
  const fetchData = async () => {
    // Send an HTTP GET request to the specified URL
    const response = await axios.get("https://www.api.mavenevents.ae/api/blog");
    // Update the 'data' state with the data from the API response
    const blogs = [];

    for (let value of response.data) {
      blogs.push({
        url: `/blog/${value.id}`,
        src: `https://www.api.mavenevents.ae/blog/${value.imageFile}`,
        alt: "Blog",
        date: value.createdAt.split("T")[0],
        title: value.title,
      });
    }
    setData(blogs);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData();
  }, []);
  return (
    <>
     {/* hemlet */}
     <Helmet>
        <meta
          name="description"
          content="Maven Events - about us" 
        />
        <meta
          name="keywords"
          content="Maven Events, abu dhabi, Digital Agency, partners, 
          Professional Photographers, Professional Videographers, High quality editing, Promotional videos, Social media content, Profile Optimization, Posting & Advertising, Technical SEO, On-Page SEO, Off-Page SEO, 
          E-commerce, Shopify, Custom Web, WordPress Development, Digital Designing, Printing Material, Voice Over, Creative Development Resources, Informational Email, Transactional Email & Digital Newsletter"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      {/* Start Page Heading Section */}
      <PageHeading
        title="About Us"
        bgSrc="images/about_hero_bg.webp"
        pageLinkText="About Us"
      />
      {/* End Page Heading Section */}

      {/* Start About Section */}
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-5 col-lg-7">
            <Div className="cs-section_heading cs-style1">
              <h3 className="cs-section_subtitle" style={{color:'#f4b231'}}>
                {parse("About Our Agency")}
              </h3>
              <h2 className="cs-section_title" style={{ color: "white" }}>
                {parse("Who We Are")}
              </h2>
              <Spacing lg="30" md="20" />
              <p className="cs-m0">
                Maven Events is a Multimedia company located in Abu
                Dhabi, UAE. Our founders have been in the industry for over 16
                years and have worked throughout UAE and Canada providing events
                management and media services for over 120+ clients. the Maven
                team consists of a diverse range of talented local staff to
                fully serve all of our unique clients. We pride ourselves on
                working with our clients to fully understand their needs down to
                the smallest detail.
              </p>
              <Spacing lg="30" md="30" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="40" />
            </Div>
          </Div>
          <Div className="col-lg-5 offset-xl-2">
            <img
              src="/images/a_about/mavenAbout2.webp"
              alt="About"
              className="w-100 cs-radius_15 imageAboutHik"
            />
            <Spacing lg="25" md="25" />
          </Div>
          <Div className="col-lg-7">
            <img
              src="/images/a_about/mavenAbout.webp"
              alt="About"
              className="w-100 cs-radius_15 imageAboutHik"
            />
            <Spacing lg="25" md="25" />
          </Div>
          <Div className="col-lg-5">
            <img
              src="/images/a_about/mavenAbout3.webp"
              alt="About"
              className="w-100 cs-radius_15 imageAboutHik"
            />
            <Spacing lg="25" md="25" />
          </Div>
        </Div>
      </Div>
      <Spacing lg="75" md="55" />
      {/* End About Section */}

      {/* Start Why Choose Section */}
      <Spacing lg="100" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-image_layer cs-style1">
              <Div className="cs-image_layer_in">
                <img
                  src="/images/a_teamMember/mavenTeam.webp"
                  alt="About"
                  className="w-100 cs-radius_15"
                />
              </Div>
            </Div>
            <Spacing lg="0" md="40" />
          </Div>
          <Div className="col-xl-5 offset-xl-1 col-lg-6">
            <Div className="cs-section_heading cs-style1">
              <h3 className="cs-section_subtitle"style={{color:'#f4b231'}}>{parse("Why Choose Us")}</h3>
              <h2 className="cs-section_title" style={{ color: "white" }}>
                {parse("Highly experienced team with us")}
              </h2>
              <Spacing lg="30" md="20" />
              <p className="cs-m0">
                This is the main factor that sets us apart from our competition
                and allows us to deliver a specialist business consultancy
                service. Our team applies its wide-ranging experience to
                determining. Through our years of experience, we’ve also learned
                that while each channel.
              </p>
              <Spacing lg="15" md="15" />
              <p className="cs-m0">
                This is the main factor that sets us apart from our competition
                and allows us to deliver a specialist business consultancy
                service. Our team applies its wide-ranging experience to
                determining.
              </p>
              <Spacing lg="30" md="30" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="0" />
            </Div>
          </Div>
        </Div>
      </Div>
      {/* End Why Choose Section */}
      {/* Start Blog Section */}
      <Spacing lg="80" md="40" />
      <Div className="cs-shape_wrap_4">

        <Div className="container">
          <Div className="row">
            <Div className="col-xl-4">
               <Div className="cs-section_heading cs-style1">
              <h3 className="cs-section_subtitle"style={{color:'#f4b231'}}>{parse("Our Blogs")}</h3>
              <h2 className="cs-section_title" style={{ color: "white" }}>
                {parse("Explore recent publication")}
              </h2>
              <Spacing lg="80" md="50" />
              </Div>
              <Spacing lg="90" md="45" />
            </Div>
            <Div className="col-xl-7 offset-xl-1">
              <Div className="cs-half_of_full_width">
                <PostSlider blogs={data} />
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      <Spacing lg="80" md="50" />
      {/* End Blog Section */}

      {/* Start Team Section */}
      {/* End Team Section */}
      <Spacing lg="80" md="40" />
      <Div className="container">
        <Cta
          title="Let’s disscuse make <br />something <i>cool</i> together"
          btnText="Contact Us"
          btnLink="/contact"
          bgSrc="/images/cta_bg.webp"
        />
      </Div>
      <Spacing lg="40" md="20" />
    </>
  );
}

import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import { pageTitle } from "../../helper";
import Div from "../Div";
import PageHeading from "../PageHeading";
import SectionHeading from "../SectionHeading";
import Spacing from "../Spacing";
import ContactInfoWidget from "../Widget/ContactInfoWidget";
import axios from "axios";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";

export default function ContactPage() {
  const [data, setData] = useState([]);
  pageTitle("Contact Us");
  useEffect(() => {
    fetchData();
    window.scrollTo(0, 0);
  }, []);
  const fetchData = async () => {
    // Send an HTTP GET request to the specified URL
    const response = await axios.get("https://www.api.mavenevents.ae/api/services/");

    // Update the 'data' state with the data from the API response
    setData(response.data);
  };
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const validationSchema = Yup.object().shape(
    {
      name: Yup.string()
        .required("Please enter name")
        .min(2, "Name must be at least 1 characters"),
      service: Yup.string().required("Please select a service"),
      businessName: Yup.string()
        .required("Please enter the Business name")
        .min(2, "The Business name must be at least 2 characters"),
      message: Yup.string().when("message", (val, schema) => {
        if (val?.length > 1) {
          //if address exist then apply min max else not
          return Yup.string()
            .min(5, "Message must be at least 5 characters")
            .required("Required");
        } else {
          return Yup.string().notRequired();
        }
      }),
      email: Yup.string()
        .required("Please enter the email ")
        .email("must be email format"),
      phone: Yup.string()
        .required("Please enter the phone number")
        .matches(phoneRegExp, "Phone number is not valid")
        .min(10, "The phone number must be 10 numbers")
        .max(10, "The phone number must be 10 numbers"),
    },
    [["message", "message"]]
  );
  const initialValues = {
    name: "",
    email: "",
    businessName: "",
    phone: "",
    message: "",
    service: "",
  };

  const onSubmit = async (values) => {
    const response = await axios.post("https://www.api.mavenevents.ae/api/appointments/",{
      ...values
    });
    if (Array.isArray(response.data.error)) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < response.data.error.length; i++) {
        toast.error(response.data.error[i].msg.message, {
          position: "bottom-right",
           draggable: false 
        })
      }
      return false
    } else{
      toast.success("Project Send 🎉", {
        position: "bottom-right",
        draggable: false 
      }) 
      return response.data
    }

  };

  return (
    <>
    {/* helmet */}
    <Helmet>
        <meta
          name="description"
          content="Maven Events contact us"
        />
        <meta
          name="keywords"
          content="Maven, Maven Events, contact us, digital marketing abu dhabi, call us, marketing projects, abu dhabi, business, get in touch, abu dhabi marketing"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <PageHeading
        title="Contact Us"
        bgSrc="/images/misfotos/mavenContacUs.webp"
        pageLinkText="Contact"
      />
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-lg-6">
            <SectionHeading
              title="Do you have a project <br/>in your mind?"
              subtitle="Get in Touch"
            />
            <Spacing lg="55" md="30" />
            <ContactInfoWidget withIcon />
            <Spacing lg="0" md="50" />
          </Div>
          <Div className="col-lg-6">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              validator={() => ({})}
              onSubmit={async (values, { resetForm }) => {
                onSubmit(values).then((data)=>{
                  if (data) {
                    resetForm();
                  }
                });

              }}
            >
              <Form className="row">
                <Div className="col-sm-6">
                  <label className="cs-primary_color">Full Name*</label>
                  <Field
                    name="name"
                    type="text"
                    className="cs-form_field"
                    placeholder="your name..."
                  />
                  <ErrorMessage name="name">
                    {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                  </ErrorMessage>
                  <Spacing lg="20" md="20" />
                </Div>
                <Div className="col-sm-6">
                  <label className="cs-primary_color">Email*</label>
                  <Field
                    name="email"
                    type="text"
                    className="cs-form_field"
                    placeholder="your email..."
                  />
                  <ErrorMessage name="email">
                    {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                  </ErrorMessage>
                  <Spacing lg="20" md="20" />
                </Div>
                <Div className="col-sm-6">
                  <label className="cs-primary_color">Business Name*</label>
                  <Field
                    name="businessName"
                    type="text"
                    className="cs-form_field"
                    placeholder="your company..."
                  />
                  <ErrorMessage name="businessName">
                    {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                  </ErrorMessage>
                  <Spacing lg="20" md="20" />
                </Div>
                <Div className="col-sm-6">
                  <label className="cs-primary_color">Project Type*</label>
                  <Field name="service" as="select" className="cs-form_field">
                    <option value={""}>Select a Service</option>
                    {data.map((option) => (
                      <option key={option.name} value={option.name}>
                        {option.name}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage name="service">
                    {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                  </ErrorMessage>
                  <Spacing lg="20" md="20" />
                </Div>
                <Div className="col-sm-6">
                  <label className="cs-primary_color">Mobile*</label>
                  <Field
                    name="phone"
                    type="text"
                    className="cs-form_field"
                    placeholder="0x xxx xxxx"
                  />
                  <ErrorMessage name="phone">
                    {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                  </ErrorMessage>
                  <Spacing lg="20" md="20" />
                </Div>
                <Div className="col-sm-12">
                  <label className="cs-primary_color">Message</label>
                  <Field
                    name="message"
                    as="textarea"
                    cols="30"
                    rows="7"
                    type="text"
                    className="cs-form_field"
                    placeholder="write a message to know you more..."
                  />
                  <ErrorMessage name="message">
                    {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                  </ErrorMessage>
                  <Spacing lg="25" md="25" />
                </Div>
                <Div className="col-sm-12">
                  <button className="cs-btn cs-style1" type="submit">
                    <span>Submit</span>
                    <Icon icon="bi:arrow-right" />
                  </button>
                </Div>
              </Form>
            </Formik>
          </Div>
        </Div>
      </Div>
      <Spacing lg="80" md="40" />
      <div className="cs-google_map" style={{borderStyle:'solid', borderColor:'black', borderLeft:'0px', borderWidth:'2px'}}>
        <iframe
           src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=10%20Jabal%20Houq%20St%20-%20Al%20Bateen%20-%20W10%20-%20Abu%20Dhabi+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
           title="Google Map"
          allowFullScreen
        />
      </div>
      <Spacing lg="80" md="60" />
    </>
  );
}
import React, { useEffect } from 'react';
import { pageTitle } from '../../helper';
import Accordion from '../Accordion';
import Cta from '../Cta';
import Div from '../Div';
import PageHeading from '../PageHeading';
import Spacing from '../Spacing';
import { Helmet } from "react-helmet";

export default function   FaqPage() {
  pageTitle('Frequently Asked Questions');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
     {/* helmet */}
     <Helmet>
        <meta
          name="description"
          content="Maven Events - frequently asked question "
        />
        <meta
          name="keywords"
          content="frequently asked question, Maven Events, digital marketing, abu dhabi, event management, indoor photogoraphy, outdoor photography, special events, special theme, social media management, content creation, collaboration, influencer marketing"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>

      <PageHeading
        title="Frequently Asked Questions"
        bgSrc="/images/misfotos/wallpaperflare.com_wallpaper.webp"
        pageLinkText="FAQ"
      />
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-lg-4  cs-hidden_mobile">
            <div className="cs-faq_nav cs-radius_15 cs-shine_hover_1" style={{alignItems:'center' , display:'flex' ,backgroundImage:"url('/images/a_faqBanner/faq banner 2.webp')", backgroundSize:'contain'}}>
              <Div className="cs-radius_15 "style={{alignContent:'center'}}>
          
            </Div>
            </div>
          </Div>
          <Div className="col-lg-7 offset-lg-1">
            <Spacing lg="0" md="40" />
            <Accordion />
          </Div>
        </Div>
      </Div>
      <Spacing lg="60" md="30" />
      {/* Start CTA Section */}
      <Div className="container">
        <Cta
          title="Let’s make something<br /> <i>cool</i> together"
          btnText="Contact Us"
          btnLink="/contact"
          bgSrc="/images/cta_bg.webp"
        />
      </Div>
      {/* End CTA Section */}
      <Spacing lg="80" md="60" />
    </>
  );
}

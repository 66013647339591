import { Route, Routes } from 'react-router-dom';
import AboutPage from './components/Pages/AboutPage';
import BlogPage from './components/Pages/BlogPage';
import BlogDetailsPage from './components/Pages/BlogDetailsPage';
import ContactPage from './components/Pages/ContactPage';
import ErrorPage from './components/Pages/ErrorPage';
import Home from './components/Pages/Home';
import PortfolioDetailsPage from './components/Pages/PortfolioDetailsPage';
import ServicesPage from './components/Pages/ServicesPage';
import PortfolioPage from './components/Pages/PortfolioPage';
import PhotographyAgencyHome from './components/Pages/PhotographyAgencyHome';
import DigitalAgencyHome from './components/Pages/DigitalAgencyHome';
import MarketingAgencyHome from './components/Pages/MarketingAgencyHome';
// eslint-disable-next-line 
import ShowcasePortfolioHome from './components/Pages/ShowcasePortfolioHome';
import Layout from './components/Layout';
import CaseStudyDetailsPage from './components/Pages/CaseStudyDetailsPage';
import FaqPage from './components/Pages/FaqPage';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CareerPage from './components/Pages/CareerPage'
import CareerDetails from './components/Pages/CareerDetails';
import OurClient from './components/Pages/OurClientsPage'
function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={< PhotographyAgencyHome/>} />
          <Route
            path="photography-agency"
            element={<Home />}
          />
          <Route path="contact" element={<ContactPage />} />
          <Route path="career" element={<CareerPage />} />
          <Route path="career/careerDetail/:id" element={<CareerDetails />} />
          <Route path="faq" element={<FaqPage />} />
          <Route
            path="/case-study/SocialMediaMarketing"
            element={<CaseStudyDetailsPage />}
          />
          <Route path="blog" element={<BlogPage />} />
          <Route path="blog/:blogId" element={<BlogDetailsPage />} />
          <Route path="portfolio" element={<PortfolioPage />} />
          <Route
            path="portfolio/:portfolioId"
            element={<PortfolioDetailsPage />}
          />
          <Route path="ourClients" element={<OurClient />} />
          <Route path="service" element={<ServicesPage />} />
          <Route path="digital-agency" element={<DigitalAgencyHome />} />
          <Route path="marketing-agency" element={<MarketingAgencyHome />} />
          <Route path="about" element={<AboutPage />} />
        </Route>
        <Route
          path="/"
          element={<Layout headerVariant="cs-site_header_full_width" />}
        >
        </Route>
        <Route path="*" element={<ErrorPage />} />
      </Routes>
      <ToastContainer />
    </>
  );
}

export default App;

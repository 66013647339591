import React, { useEffect } from "react";
import Card from "../Card";
import Hero from "../Hero";
import Div from "../Div";
import SectionHeading from "../SectionHeading";
import Spacing from "../Spacing";
import Cta from "../Cta";
import MovingText from "../MovingText";
import TestimonialSlider from "../Slider/TestimonialSlider";
import VideoModal from "../VideoModal";
import { pageTitle } from "../../helper";
import MovingText2 from "../MovingLogo";
import { Helmet } from "react-helmet";
export default function Photography() {
  pageTitle("Photography Agency");

  // Hero Social Links
  const heroSocialLinks = [
    {
      name: "Instagram",
      links: "https://www.instagram.com/maven.ae",
    },
    {
      name: "Facebook",
      links: "https://www.facebook.com/people/Maven-Events/61557239189183/",
    },
    {
      name: "YouTube",
      links: "https://www.youtube.com/channel/UCD-sZpls3rdmwedzap3yqEA",
    },
    {
      name: "TikTok",
      links: "https://www.tiktok.com/@maven.ae",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {/* helmet */}
      <Helmet>
        <meta
          name="keywords"
          content="Maven, Maven Events, photography,videography, social media content, studio shoots, content creation, media services, portfolio, restaurants shoots, menu shoots, social media, photography studio,product photography,تصوير فوتوغرافي, فوتوغرافي, محل تصوير فوتوغرافي, تصوير الفيديو, استديو تصوير, اقرب محل تصوير فوتوغرافي, "
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="description" content="photography and videography agency" />
      </Helmet>
      {/* Start Hero Section */}
      <Hero
        title="Let us help you to<br/>Show <p style='color:#f4b231;display:inline'>the world</p> your <br/> <p style='color:#f4b231;display:inline'>unique</p> side "
        subtitle=""
        btnText="Contact Us"
        btnLink="/contact"
        scrollDownId="#service"
        socialLinksHeading="Follow Us"
        heroSocialLinks={heroSocialLinks}
        bgImageUrl="images/hero_bg_2.webp"
      />
      {/* End Hero Section */}

      {/* Start Service Section */}
      <Spacing lg="150" md="80" />
      <Div id="service" className="cs-hidden_mobile">
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-4">
              <SectionHeading
                title="Services we can help you with"
                subtitle="What Can We Do"
                btnText="See All Services"
                btnLink="/service"
              />
              <Spacing lg="90" md="45" />
            </Div>
            <Div className="col-xl-8">
              <Div className="row">
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
                <Div className="col-lg-3 col-sm-6 ">
                  <Card
                    title="Photography"
                    link="#"
                    src="/images/a_Services/Artboard1.webp"
                    alt="Service"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="Videography"
                    link="#"
                    src="/images/service_5.webp"
                    alt="Service"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="Creative Design"
                    link="#"
                    src="/images/a_Services/Artboard4.webp"
                    alt="Service"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="Branding"
                    link="#"
                    src="/images/a_Services/Artboard5.webp"
                    alt="Service"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      {/* End Service Section */}

      {/* Start LogoList Section */}
      <Spacing lg="150" md="50" />
      {/* Start MovingText Section */}
      <MovingText text="Our reputed world wide partners" />
      {/* End MovingText Section */}
      <MovingText2 />
      {/* End LogoList Section */}

      {/* Start Video Block Section */}
      <Spacing lg="130" md="70" />
      <Div className="container">
        <h2 className="cs-font_40 cs-m0 text-center cs-line_height_4">
          Our agile process is ability to adapt and respond to change. Agile
          organizations view change as an opportunity.
        </h2>
        <Spacing lg="70" md="70" />
        <VideoModal
          videoSrc="https://www.youtube.com/watch?v=9d26NVbGsZA"
          bgUrl="/images/video_bg.webp"
        />
      </Div>
      <Spacing lg="150" md="50" />
      {/* End Video Block Section */}

      {/* Start Testimonial Section */}
      <TestimonialSlider />
      {/* End Testimonial Section */}
      <Spacing lg="150" md="50" />
      {/* Start CTA Section */}
      <Div className="container">
        <Cta
          title="Let’s disscuse make <br />something <i>cool</i> together"
          btnText="Apply For Meeting"
          btnLink="/contact"
          bgSrc="/images/cta_bg.webp"
        />
      </Div>
      {/* End CTA Section */}
      <Spacing lg="150" md="50" />
    </>
  );
}

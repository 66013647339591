import React, { useEffect } from 'react'
import { pageTitle } from '../../helper'
import Card from '../Card'
import Cta from '../Cta'
import PageHeading from '../PageHeading'
import Div from '../Div'
import SectionHeading from '../SectionHeading'
import TestimonialSlider from '../Slider/TestimonialSlider'
import Spacing from '../Spacing'
import Portfolio3 from '../Portfolio/Portfolio3'
import Portfolio2 from '../Portfolio/Portfolio2'
import { Helmet } from "react-helmet";

export default function ServicesPage() {
  pageTitle('Service');
  const portfolioData = [
    {
      title: 'Professional Photographers & Videographers, High quality editing, Promotional videos & Social media content.',
      subtitle: '',
      btnText: '',
      btnLink: '#',
      imageUrl: '/images/a_Services/Artboard1.webp',
      category: 'Photography & Videography',
    },
    {
      title: 'Profile Optimization, Posting & Advertising.',
      subtitle: '',
      btnText: '',
      btnLink: '#',
      imageUrl: '/images/a_Services/Artboard2.webp',
      category: 'Social Media Marketing',
    },
    {
      title: 'Technical SEO, On-Page SEO & Off-Page SEO.',
      subtitle: '',
      btnText: '',
      btnLink: '#',
      imageUrl: '/images/a_Services/Artboard3.webp',
      category: 'SEO Services',
    },
    {
      title: 'E-commerce, Shopify, Custom Web, WordPress Development.',
      subtitle: '',
      btnText: '',
      btnLink: '#',
      imageUrl: '/images/a_Services/Artboard4.webp',
      category: 'Website & QR Design',
    },
    {
      title: 'Digital Designing, Printing Material.',
      subtitle: '',
      btnText: '',
      btnLink: '#',
      imageUrl: '/images/a_Services/Artboard5.webp',
      category: 'Graphic Designing',
    },
    {
      title: 'Voice Over.',
      subtitle: '',
      btnText: '',
      btnLink: '#',
      imageUrl: '/images/a_Services/Artboard10.webp',
      category: 'Voice Over',
    },
    {
      title: 'Ticketing and Box Office, Ushers, Customer Service, Hostesses, Professional Dancers and Models & Site Management of Events.',
      subtitle: '',
      btnText: '',
      btnLink: '#',
      imageUrl: '/images/a_Services/Artboard9.webp',
      category: 'Event Management',
    },
    {
      title: 'Directing and training educational programs , Creative Development Resources & Show design.',
      subtitle: '',
      btnText: '',
      btnLink: '#',
      imageUrl: '/images/a_Services/Artboard11.webp',
      category: 'Education Programs',
    },
    {
      title: 'High quality sound, Music tailored to your event & Latest equipment.',
      subtitle: '',
      btnText: '',
      btnLink: '#',
      imageUrl: '/images/a_Services/Artboard8.webp',
      category: 'Professional DJ',
    },
    {
      title: 'Concept Design, Production of Marketing Materials , Hiring of Hostesses, Ushers and Translators.',
      subtitle: '',
      btnText: '',
      btnLink: '#',
      imageUrl: '/images/a_Services/Artboard7.webp',
      category: 'Exhibition & Conference',
    },
    {
      title: 'Informational Email, Transactional Email & Digital Newsletter.',
      subtitle: '',
      btnText: '',
      btnLink: '#',
      imageUrl: '/images/a_Services/Artboard6.webp',
      category: 'Email Marketing',
    },
    
  ];
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
    {/* hemlet */}
    <Helmet>
        <meta
          name="description"
          content="Maven Events - Multimedia services services" 
        />
        <meta
          name="keywords"
          content="Maven Events, abu dhabi, Professional Photographers, Professional Videographers, High quality editing, Promotional videos, Social media content, Profile Optimization, Posting & Advertising, Technical SEO, On-Page SEO, Off-Page SEO, 
          E-commerce, Shopify, Custom Web, WordPress Development, Digital Designing, Printing Material, Voice Over, Ticketing and Box Office, Ushers, Customer Service, Hostesses, Professional Dancers and Models & Site Management of Events, 
          Directing and training educational programs , Creative Development Resources & Show design, High quality sound, Music tailored to your event, Informational Email, Transactional Email & Digital Newsletter"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      {/* page */}
      <PageHeading 
        title='What Can We Do'
        bgSrc='images/service_hero_bg.webp'
        pageLinkText='Services'
      />
      <div className='hiddenHik'>


      <Spacing lg='150' md='80'/>
      <Div className='cs-shape_wrap_4'>
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-4">
              <SectionHeading
                title='Services we can help you with' 
                subtitle=''
              />
              <Spacing lg='90' md='45'/>
            </Div>
            <Div className='col-xl-8'>
              <Div className='row'>
                <Div className='col-lg-3 col-sm-6 cs-hidden_mobile'></Div>
                <Div className='col-lg-3 col-sm-6'>
                  <Card
                    title='Photography'
                    src='/images/a_Services/Artboard1.webp'
                    alt='Service'
                  />
                  <Spacing lg='0' md='30'/>
                </Div>
                <Div className='col-lg-3 col-sm-6 cs-hidden_mobile'></Div>
                <Div className='col-lg-3 col-sm-6'>
                  <Card
                    title='Social Media Marketing'
                    src='/images/a_Services/Artboard2.webp'
                    alt='Service'
                  />
                  <Spacing lg='0' md='30'/>
                </Div>
                <Div className='col-lg-3 col-sm-6'>
                  <Card
                    title='SEO Services'
                    src='/images/a_Services/Artboard3.webp'
                    alt='Service'
                  />
                  <Spacing lg='0' md='30'/>
                </Div>
                <Div className='col-lg-3 col-sm-6 cs-hidden_mobile'></Div>
                <Div className='col-lg-3 col-sm-6'>
                  <Card
                    title='Website & QR Design'
                    src='/images/a_Services/Artboard4.webp'
                    alt='Service'
                  />
                  <Spacing lg='0' md='30'/>
                </Div>
                <Div className='col-lg-3 col-sm-6 cs-hidden_mobile'></Div>

                <Div className='col-lg-3 col-sm-6 cs-hidden_mobile'></Div>
                <Div className='col-lg-3 col-sm-6'>
                  <Card
                    title='Graphic Designing'
                    src='/images/a_Services/Artboard5.webp'
                    alt='Service'
                  />
                  <Spacing lg='0' md='30'/>
                </Div>
                <Div className='col-lg-3 col-sm-6 cs-hidden_mobile'></Div>
                <Div className='col-lg-3 col-sm-6'>
                  <Card
                    title='Voice Over'
                    src='/images/a_Services/Artboard10.webp'
                    alt='Service'
                  />
                  <Spacing lg='0' md='30'/>
                </Div>

                
                <Div className='col-lg-3 col-sm-6'>
                  <Card
                    title='Event Managment'
                    src='/images/a_Services/Artboard9.webp'
                    alt='Service'
                  />
                  <Spacing lg='0' md='30'/>
                </Div>
                <Div className='col-lg-3 col-sm-6 cs-hidden_mobile'></Div>

                <Div className='col-lg-3 col-sm-6'>
                  <Card
                    title='Email Marketing'
                    src='/images/a_Services/Artboard6.webp'
                    alt='Service'
                  />
                  <Spacing lg='0' md='30'/>
                </Div>
                
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      </div>
 {/* Start Portfolio Section */}
 <Spacing lg="150" md="80" />
      {portfolioData.map((item, index) =>
        index % 2 === 0 ? (
          <Div key={index}>
            <Portfolio2
              title={item.title}
              subtitle={item.subtitle}
              btnText={item.btnText}
              btnLink={item.btnLink}
              imageUrl={item.imageUrl}
              category={item.category}
            />
             <Spacing lg='20' md='40'/>
          </Div>
        ) : (
          <Div key={index}>
            <Portfolio3
              title={item.title}
              subtitle={item.subtitle}
              btnText={item.btnText}
              btnLink={item.btnLink}
              imageUrl={item.imageUrl}
              category={item.category}
            />
             <Spacing lg='100' md='100'/>
          </Div>
        ),
      )}
      {/* End Portfolio Section */}

      <Spacing lg='20' md='40'/>
      <TestimonialSlider/>
      <Spacing lg='80' md='40'/>
      <Div className="container">
        <Cta 
          title='Let’s disscuse make <br />something <i>cool</i> together' 
          btnText='Contact us' 
          btnLink='/contact' 
          bgSrc='/images/cta_bg.webp'
        />
          <Spacing lg='80' md='40'/>
      </Div>
    </>
  )
}
